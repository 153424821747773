import * as Sentry from "@sentry/react";
import axios from "axios";
import { replace } from "connected-react-router";
import merge from "lodash/merge";
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading
} from "redux-saga/effects";
import { RootState } from "..";
import { User } from "../../entities/user";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { getLanguage } from "../app/selector";
import {
  BriefConfiguratorClear,
  BriefConfiguratorHasChangeAfterSubmit,
  BriefConfiguratorSelectBriefElement
} from "../briefConfigurator/action";
import { BriefSelected } from "../briefs/action";
import { SectionNames } from "../configurator-inputs/constant";
import * as ProductAPI from "../products/api";
import { ProjectSetContext } from "../projects/action";
import { InitialScale3D, Product } from "./../products/entity";
import {
  BriefElementConfiguratorActionsTypes,
  BriefElementConfiguratorAdd,
  BriefElementConfiguratorClear,
  BriefElementConfiguratorRemove,
  BriefElementConfiguratorRemoveAndSelect,
  BriefElementConfiguratorSectionExpand,
  BriefElementConfiguratorSetInitialValues,
  BriefElementConfiguratorSetProductSelected,
  BriefElementConfiguratorSetProductSelectedScale,
  BriefElementConfiguratorSetProductUrl,
  BriefElementConfiguratorSetProductVisibilityState,
  BriefElementConfiguratorSetValues,
  BriefElementConfiguratorSetWithProduct
} from "./action";
import { BriefElementConfiguratorInitialValues } from "./constant";

interface UserData {
  data: User;
}

interface ProductData {
  data: Product;
}

export function* addAndSelectBriefElement() {
  yield put(new BriefConfiguratorHasChangeAfterSubmit(true));
  const briefElementStateKeys = yield select((state: RootState) =>
    Object.keys(state.briefElementConfigurator).map((k) => Number(k))
  );
  const nextPosition = (Math.max(...briefElementStateKeys) + 1).toString();
  yield put(new BriefElementConfiguratorAdd(nextPosition));
  yield put(new BriefConfiguratorSelectBriefElement(nextPosition.length));
}

export function* removeAndSelectBriefElement(
  action: BriefElementConfiguratorRemoveAndSelect
) {
  yield put(new BriefConfiguratorHasChangeAfterSubmit(true));
  yield put(new BriefElementConfiguratorRemove(action.position));

  // if (briefSelected === action.position) {
  yield put(new BriefConfiguratorSelectBriefElement(0));
  // } else {
  //   yield put(new BriefConfiguratorSelectBriefElement(briefElementsLength - 1));
  // }
}

export function* setWithProduct(
  action: BriefElementConfiguratorSetWithProduct
): any {
  /* Clear out to prepare a new configuration */
  yield put(new BriefSelected());
  yield put(new BriefConfiguratorClear());
  yield put(new BriefElementConfiguratorClear());
  yield put(new ProjectSetContext(undefined));

  const { position, productId, subcategory, isValidToken } = action;
  let isNotAllowed = false;
  let currentUser;

  try {
    if (isValidToken && localStorage.getItem("token")) {
      const response: UserData = yield axios.get("/sign-in-by-token", {
        baseURL: process.env.REACT_APP_API || "/api",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      currentUser = response.data;
    } else {
      currentUser = {
        cmsToken: null,
        collaborationIds: [],
        collaborations: [],
        companyName: "",
        deletedAt: null,
        invitationStatus: null,
        isActive: true,
        job: null,
        lastName: null,
        onboarded: true,
        origin: null,
        roleIds: [],
        roles: []
      };
    }

    const { data: product } = yield call(ProductAPI.readOne, {
      id: productId
    });

    if (currentUser!.roleIds.length > 0) {
      isNotAllowed = product.roleIds?.includes(currentUser!.roleIds[0]);
    } else {
      isNotAllowed = !product.isAllowingGuest;
    }

    const lang: string = yield select(getLanguage);
    if (!product.enabled) {
      throw new Error(
        i18n.t("saga:products.notEnable", {
          name: jsonTranslator(product.label, lang)
        })
      );
    }

    const nextBriefElementValues = merge(
      {},
      BriefElementConfiguratorInitialValues,
      {
        productCategoryId: product.productCategoryId,
        productId: product.id,
        subCategoriesIds: subcategory ? [subcategory] : []
      }
    );
    yield put(
      new BriefElementConfiguratorSetInitialValues(
        position,
        nextBriefElementValues
      )
    );
    yield put(
      new BriefElementConfiguratorSetValues(position, nextBriefElementValues)
    );
    yield put(
      new BriefElementConfiguratorSetProductSelected(position, product)
    );

    yield put(
      new BriefElementConfiguratorSetProductSelectedScale(
        position,
        InitialScale3D
      )
    );

    yield put(
      new BriefElementConfiguratorSetProductVisibilityState(
        position,
        isNotAllowed
      )
    );

    if (!isNotAllowed) {
      if (product?.isQuantityOpen) {
        yield put(
          new BriefElementConfiguratorSectionExpand(
            "0",
            SectionNames.PURCHASE_CONDITION,
            true
          )
        );
      }

      yield put(
        new BriefElementConfiguratorSectionExpand(
          "0",
          SectionNames.PACKAGING_TYPE,
          true
        )
      );
    }
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:setWithProduct");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });

    yield put(sendErrorNotification(error, i18n.t("saga:setproduct-error")));
  }

  // In any case return to configurator with or without product

  yield put(new BriefElementConfiguratorSetProductUrl(position, true));
  yield put(replace("/configurator?url"));
}

export const BriefElementConfiguratorSaga = [
  takeLatest(
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_ADD_AND_SELECT,
    addAndSelectBriefElement
  ),
  takeEvery(
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_REMOVE_AND_SELECT,
    removeAndSelectBriefElement
  ),
  takeLeading(
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_WITH_PRODUCT,
    setWithProduct
  )
];
