import { Box, Grid, Typography } from "@material-ui/core";
import ConnectionIcon from "mdi-material-ui/AccessPoint";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MyPageProps, Page } from "../components/common/MyPage";
import { RootState } from "../reducers";
import { FetchingStatus } from "../utils/reducers/fetchingStatus";

export interface LoadingPageProps extends MyPageProps {
  title?: string;
}

export const LoadingPage: React.FC<LoadingPageProps> = ({
  title,
  ...props
}) => {
  const { t } = useTranslation("common");
  return (
    <Page center {...props}>
      <Box
        display="flex"
        position="absolute"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <ConnectionIcon fontSize="large" style={{ color: "grey" }} />
          <Typography variant="h5" color="textSecondary">
            {title ?? t("loading")}
          </Typography>
        </Grid>
      </Box>
    </Page>
  );
};

export const Reconnection: React.FC<{
  userIsAuthenticated: boolean;
  needAuthentication?: boolean;
  children?: React.ReactNode;
}> = ({ children, userIsAuthenticated, needAuthentication = false }) => {
  const { t } = useTranslation("reconnection");
  const retryConnectionStatus = useSelector(
    (state: RootState) => state.authentication.retryConnectionStatus
  );
  const retryConnection = retryConnectionStatus === FetchingStatus.PENDING;
  return (
    <>
      {retryConnection && <LoadingPage title={t("title")} />}
      {!needAuthentication
        ? children
        : retryConnectionStatus === FetchingStatus.SUCCESS ||
          !localStorage.getItem("token") ||
          userIsAuthenticated
        ? children
        : null}
    </>
  );
};
