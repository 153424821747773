import { makeStyles, createStyles, Box } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Theme } from "react-select";
import { MyButton } from "../../../../components/common/MyButton";
import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { useGetUserNotificationsArchivedQuery } from "../../../../services/notifications/notifications.service";
import { NotificationUserCategory } from "./NotificationUserCategory";
import { MySuspense } from "../../../../components/common/MySuspense";

import AddIcon from "@material-ui/icons/Add";
import { Notification } from "../../../../services/notifications/notifications.type";
import {
  EmptyState,
  EmptyStateContext
} from "../../../../components/common/EmptyState";

interface NotificationUserArchivedListProps {
  actionNotfication: NotificationFilter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyState: {
      "& > div > div > * > img": {
        maxWidth: "58px !important",
        maxHeight: "58px !important"
      }
    }
  })
);

export const NotificationUserArchivedList: React.FC<NotificationUserArchivedListProps> = ({
  actionNotfication
}) => {
  const classes = useStyles();

  const [cursor, setCursor] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);
  const { t } = useTranslation("backoffice");

  const {
    notifications,
    meta,
    isLoading,
    isSuccess,
    isError,
    isFetching
  } = useGetUserNotificationsArchivedQuery(
    {
      userId: currentUser.id,
      cursor,
      ...(actionNotfication && { category: actionNotfication.category })
    },
    {
      selectFromResult: (result) => {
        return {
          ...result,
          notifications: result.data?.data as Notification[],
          meta: result.data?.meta
        };
      }
    }
  );

  const renderNotifications =
    notifications?.length > 0 &&
    notifications?.map((notification: Notification) => {
      return (
        <NotificationUserCategory
          key={notification.id}
          notification={notification}
          archivingView
        />
      );
    });

  return (
    <MySuspense
      data={notifications}
      loading={[]}
      success={[isSuccess]}
      error={[isError]}
      showNoData={false}
      showResultIf={() => (notifications?.length as number) > 0 && isSuccess}
      noDataComponent={
        <>
          {isSuccess && (
            <Box className={classes.emptyState}>
              <EmptyState
                context={EmptyStateContext.IN_APP_NOTIFICATIONS_ARCHIVED}
                disableAction
                containerStyle={{ backgroundColor: "transparent" }}
              />
            </Box>
          )}
        </>
      }
    >
      <Box>
        {renderNotifications}
        {meta?.hasNextPage && (
          <Box mb={2} mt={2}>
            <MyButton
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                const createdAt = (notifications?.slice(-1)[0]
                  ?.createdAt as unknown) as string;
                setCursor(createdAt);
              }}
              leftIcon={<AddIcon color="primary" />}
            >
              {t("tasks.activities.loadMore")}
            </MyButton>
          </Box>
        )}
      </Box>
    </MySuspense>
  );
};
