import produce from "immer";
import merge from "lodash/merge";
import {
  BriefConfiguratorActions,
  BriefConfiguratorActionsTypes
} from "./action";
import { ConfiguratorContext } from "../../entities/brief";

export interface BriefConfiguratorValues {
  name?: string;
  intercom?: string;
  comment?: string;
  deliveryZipCode?: string;
  projectId?: string;
  context?: ConfiguratorContext;
  parentId?: string;
  rootId?: string;
}

export const BriefConfiguratorInitialValues: BriefConfiguratorValues = {
  name: undefined,
  intercom: undefined,
  deliveryZipCode: undefined,
  context: ConfiguratorContext.CREATION,
  parentId: undefined,
  rootId: undefined
};

export interface BriefConfiguratorState {
  briefElementSelectedPosition: number;
  submitValues: BriefConfiguratorValues;
  initialValues: BriefConfiguratorValues;
  values: BriefConfiguratorValues;
  isValid: boolean;
  submitCount: number;
  hasSubmitOnce: boolean;
  valuesHasChangedAfterSubmit: boolean;
  openSaveBriefDialog: boolean;
  openPredictionPanel: boolean;
  openRequestQuotationNoPriceDialog: boolean;
  openRequestQuotationHipeFirst: boolean;
}

export const BriefConfiguratorInitialState: BriefConfiguratorState = {
  briefElementSelectedPosition: 0,
  submitValues: BriefConfiguratorInitialValues,
  initialValues: BriefConfiguratorInitialValues,
  values: BriefConfiguratorInitialValues,
  isValid: false,
  submitCount: 0,
  hasSubmitOnce: false,
  valuesHasChangedAfterSubmit: false,
  openSaveBriefDialog: false,
  openPredictionPanel: false,
  openRequestQuotationNoPriceDialog: false,
  openRequestQuotationHipeFirst: false
};

export const BriefConfiguratorReducer = (
  state = BriefConfiguratorInitialState,
  action: BriefConfiguratorActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SELECT_BRIEF_ELEMENT:
        draft.briefElementSelectedPosition = action.position;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SUBMIT:
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_SUBMIT_VALUES:
        draft.submitValues = action.submitValues;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_VALUES:
        draft.values = action.values || BriefConfiguratorInitialValues;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_INITIAL_VALUES:
        draft.initialValues =
          action.initialValues || BriefConfiguratorInitialValues;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_UPDATE_VALUES:
        draft.values = merge({}, action.values, draft.values);
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_ON_CHANGE:
        draft.values = action.values;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_SUBMIT_COUNT:
        draft.submitCount = action.count;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_HAS_SUBMIT_ONCE:
        draft.hasSubmitOnce = true;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_HAS_CHANGE_AFTER_SUBMIT:
        draft.valuesHasChangedAfterSubmit = action.hasChange;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_IS_VALID:
        draft.isValid = action.isValid;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_SAVE_BRIEF_SUCCESS:
        draft.openSaveBriefDialog = action.open;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_PREDICTION_PANEL:
        draft.openPredictionPanel = action.open;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_REQUEST_QUOTATION_NO_PRICE:
        draft.openRequestQuotationNoPriceDialog = action.open;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_REQUEST_HIPE_FIRST:
        draft.openRequestQuotationHipeFirst = action.open;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_CLEAR:
        return BriefConfiguratorInitialState;
      default:
        return draft;
    }
  });
