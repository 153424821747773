import { BriefConfiguratorValues } from "./reducer";
import { DeepPartial } from "redux";

export enum BriefConfiguratorActionsTypes {
  BRIEF_CONFIGURATOR_SELECT_BRIEF_ELEMENT = "@@BRIEF_CONFIGURATOR select brief element",
  BRIEF_CONFIGURATOR_SUBMIT = "@@BRIEF_CONFIGURATOR user has submit the form",
  BRIEF_CONFIGURATOR_UPDATE_VALUES = "@@BRIEF_CONFIGURATOR update values",
  BRIEF_CONFIGURATOR_SET_VALUES = "@@BRIEF_CONFIGURATOR set values",
  BRIEF_CONFIGURATOR_SET_INITIAL_VALUES = "@@BRIEF_CONFIGURATOR set initial values",
  BRIEF_CONFIGURATOR_SET_SUBMIT_VALUES = "@@BRIEF_CONFIGURATOR set submit values",
  BRIEF_CONFIGURATOR_SET_SUBMIT_COUNT = "@@BRIEF_CONFIGURATOR set submit count",
  BRIEF_CONFIGURATOR_HAS_SUBMIT_ONCE = "@@BRIEF_CONFIGURATOR has submit once",
  BRIEF_CONFIGURATOR_HAS_CHANGE_AFTER_SUBMIT = "@@BRIEF_CONFIGURATOR has change after submit",
  BRIEF_CONFIGURATOR_IS_VALID = "@@BRIEF_CONIFIGURATOR is valid",
  BRIEF_CONFIGURATOR_ON_CHANGE = "@@BRIEF_CONFIGURATOR on change",
  BRIEF_CONFIGURATOR_OPEN_ORDER_SUCCESS = "@@BRIEF_CONFIGURATOR open order success",
  BRIEF_CONFIGURATOR_OPEN_SAVE_BRIEF_SUCCESS = "@@BRIEF_CONFIGURATOR open save brief success",
  BRIEF_CONFIGURATOR_OPEN_PREDICTION_PANEL = "@@BRIEF_CONFIGURATOR open prediction panel",
  BRIEF_CONFIGURATOR_OPEN_REQUEST_QUOTATION_NO_PRICE = "@@BRIEF_CONFIGURATOR open request quotation with no price",
  BRIEF_CONFIGURATOR_OPEN_REQUEST_HIPE_FIRST = "@@BRIEF_CONFIGURATOR open request quotation with hipe first",
  BRIEF_CONFIGURATOR_CLEAR = "@@BRIEF_CONFIGURATOR clear",
  BRIEF_CONFIGURATOR_INIT = "@@BRIEF_CONFIGURATOR init"
}

export class BriefConfiguratorSelectBriefElement {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SELECT_BRIEF_ELEMENT;
  constructor(readonly position: number) {}
}

export class BriefConfiguratorSubmit {
  readonly type = BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SUBMIT;
}

export class BriefConfiguratorUpdateValues {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_UPDATE_VALUES;
  constructor(readonly values?: DeepPartial<BriefConfiguratorValues>) {}
}

export class BriefConfiguratorSetValues {
  readonly type = BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_VALUES;
  constructor(readonly values?: BriefConfiguratorValues) {}
}

export class BriefConfiguratorSetInitialValues {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_INITIAL_VALUES;
  constructor(readonly initialValues?: BriefConfiguratorValues) {}
}

export class BriefConfiguratorSetSubmitValues {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_SUBMIT_VALUES;
  constructor(readonly submitValues: BriefConfiguratorValues) {}
}

export class BriefConfiguratorOnChange {
  readonly type = BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_ON_CHANGE;
  constructor(readonly values: BriefConfiguratorValues) {}
}

export class BriefConfiguratorSetSubmitCount {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_SUBMIT_COUNT;
  constructor(readonly count: number) {}
}

export class BriefConfiguratorHasSubmitOnce {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_HAS_SUBMIT_ONCE;
}

export class BriefConfiguratorHasChangeAfterSubmit {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_HAS_CHANGE_AFTER_SUBMIT;
  constructor(readonly hasChange: boolean) {}
}

export class BriefConfiguratorIsValid {
  readonly type = BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_IS_VALID;
  constructor(readonly isValid: boolean) {}
}

export class BriefConfiguratorOpenPredictionPanel {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_PREDICTION_PANEL;
  constructor(public readonly open: boolean) {}
}

export class BriefConfiguratorOpenSaveBriefSuccess {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_SAVE_BRIEF_SUCCESS;
  constructor(public readonly open: boolean) {}
}

export class BriefConfiguratorOpenRequestQuotationNoPrice {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_REQUEST_QUOTATION_NO_PRICE;
  constructor(public readonly open: boolean) {}
}

export class BriefConfiguratorOpenRequestHipeFirst {
  readonly type =
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_REQUEST_HIPE_FIRST;
  constructor(public readonly open: boolean) {}
}

export class BriefConfiguratorClear {
  readonly type = BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_CLEAR;
}

export class BriefConfiguratorInit {
  readonly type = BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_INIT;
}

export type BriefConfiguratorActions =
  | BriefConfiguratorSelectBriefElement
  | BriefConfiguratorSubmit
  | BriefConfiguratorUpdateValues
  | BriefConfiguratorSetValues
  | BriefConfiguratorSetInitialValues
  | BriefConfiguratorSetSubmitValues
  | BriefConfiguratorOnChange
  | BriefConfiguratorSetSubmitCount
  | BriefConfiguratorHasSubmitOnce
  | BriefConfiguratorHasChangeAfterSubmit
  | BriefConfiguratorIsValid
  | BriefConfiguratorOpenSaveBriefSuccess
  | BriefConfiguratorOpenRequestQuotationNoPrice
  | BriefConfiguratorOpenRequestHipeFirst
  | BriefConfiguratorOpenPredictionPanel
  | BriefConfiguratorClear
  | BriefConfiguratorInit;
