import { MenuList, MenuItem, Box, useMediaQuery } from "@material-ui/core";
import { FC, useState, cloneElement, useCallback } from "react";
import { object } from "yup";
import { MyFormik } from "../../../../components/common/MyFormik";
import { MyIconButton } from "../../../../components/common/MyIconButton";
import { FilterButtonProps } from "../../../../components/common/MyMaterialTable2Virtualize/components/FilterButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { jsonLogicDefault } from "../../../../reducers/configurator-input-options/constant";
import { JsonLogicResult } from "react-awesome-query-builder";
import { MetaEntityHipe } from "../../../../services/customFields/types";

const crmLocalStorageKeys = [
  "my_projects_filter",
  "projects_filter",
  "my_companies_filter",
  "companies_filter",
  "tasks_filter",
  "my_contacts_filter",
  "contacts_filter",
  "user_filter"
];

export const clearLocalStorageCrm = () => {
  for (const crmKey of crmLocalStorageKeys) {
    localStorage.removeItem(crmKey);
  }
};

const isMyContextCrm = (): boolean => {
  const location = window.location.pathname;
  return location.includes("my");
};

export const setLocalEntityFilter = (
  entityType: MetaEntityHipe,
  filterId: string
): void =>
  localStorage.setItem(
    `${isMyContextCrm() ? "my_" : ""}${entityType?.toLowerCase()}_filter`,
    filterId
  );

export const getLocalEntityFilter = (entityType: string): string =>
  localStorage.getItem(
    `${isMyContextCrm() ? "my_" : ""}${entityType?.toLowerCase()}_filter`
  ) as string;

export const cleanLocalEntityFilter = (entityType: string): void =>
  localStorage.removeItem(
    `${isMyContextCrm() ? "my_" : ""}${entityType?.toLowerCase()}_filter`
  );

export interface GenericFilterTableProps<T = unknown>
  extends Omit<FilterButtonProps, "onChange"> {
  filters: T;
  children: JSX.Element;
  search?: JSX.Element;
  showCondition?: boolean;
  setFilterCountRef?: React.MutableRefObject<number>;
  defaultJsonLogic?: JsonLogicResult;
  defaultFormula?: string;
}

type GenericFilterTableI<T = any> = FC<GenericFilterTableProps<T>>;

export const GenericFilterTable: GenericFilterTableI = ({
  filters,
  children,
  search,
  showCondition = true,
  setFilterCountRef,
  defaultJsonLogic,
  defaultFormula,
  ...rest
}) => {
  const [filtering, setIsFiltering] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  const [jsonLogicFormula, setJsonLogicFormula] = useState({
    jsonLogicFormula: defaultJsonLogic ?? (jsonLogicDefault as JsonLogicResult),
    formula: defaultFormula ?? undefined
  });

  const triggerCounter = useCallback(
    (filters: any) => {
      if (!filters) {
        setFilterCountRef!.current = 0;
        return;
      }

      const regularCounter = Object.entries(filters).filter(
        ([key, value]: [string, any]) => !!value?.length && key !== "filterId"
      ).length;

      if (setFilterCountRef) {
        const customCounter = Object.values(
          jsonLogicFormula?.jsonLogicFormula?.logic || []
        )[0]?.length as number;

        setFilterCountRef.current = customCounter
          ? customCounter + regularCounter
          : regularCounter;
      }
    },
    [jsonLogicFormula, setFilterCountRef]
  );

  return (
    <Box
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center"
      }}
    >
      {search}
      {showCondition && (
        <MyIconButton
          color={setFilterCountRef?.current ? "primary" : "inherit"}
          myMenuProps={{
            style: { padding: 10 },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          }}
          menus={({ onClose }) => (
            <MenuList
              style={{
                width: isMobile ? 350 : isTablet ? 600 : 900,
                outline: "none"
              }}
            >
              <MenuItem button={false} style={{ width: "100%" }}>
                <MyFormik
                  initialValues={{ ...filters }}
                  validationSchema={object()}
                  onSubmit={() => undefined}
                >
                  {({ values, setFieldValue, setValues }) =>
                    cloneElement(children, {
                      values,
                      setFieldValue,
                      setValues,
                      isFetching: filtering,
                      onClose,
                      count: setFilterCountRef?.current,
                      jsonLogicFormula,
                      setJsonLogicFormula,
                      triggerCounter
                    })
                  }
                </MyFormik>
              </MenuItem>
            </MenuList>
          )}
        >
          <FilterListIcon onClick={() => setIsFiltering(true)} />
        </MyIconButton>
      )}
      {!!setFilterCountRef?.current && (
        <span style={{ position: "absolute", right: "0" }}>
          {setFilterCountRef?.current}
        </span>
      )}
    </Box>
  );
};
