import * as Sentry from "@sentry/react";
import { push } from "connected-react-router";
import { has } from "lodash";
import merge from "lodash/merge";
import omit from "lodash/omit";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import {
  call,
  delay,
  put,
  putResolve,
  select,
  takeLatest
} from "redux-saga/effects";
import { RootState } from "..";
import {
  Brief,
  BriefElementWithRelations,
  BriefWithRelations,
  ConfiguratorContext
} from "../../entities/brief";
import { Company } from "../../entities/company";
import { transformInkParameters } from "../../entities/inkParameter";
import { transformLuxuryvarnishParams } from "../../entities/luxuryVarnishParameter";
import { ProductCategory } from "../../entities/productCategory";
import { Project } from "../../entities/project";
import { User } from "../../entities/user";
import { transformVarnishParams } from "../../entities/varnishParameter";
import { BriefElementConfiguratorSchema } from "../../pages/Configurator/components/common/BriefElementForm/schema";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import {
  getAliciaMessage,
  sendErrorNotification
} from "../../utils/request/error_handler";
import { AppAddSnackbar } from "../app/action";
import { getLanguage } from "../app/selector";
import {
  getCurrentUser,
  getIsGuestUser,
  getIsPackitooUser
} from "../authentication/selector";
import {
  BriefConfiguratorClear,
  BriefConfiguratorOpenPredictionPanel,
  BriefConfiguratorOpenRequestHipeFirst,
  BriefConfiguratorOpenRequestQuotationNoPrice,
  BriefConfiguratorOpenSaveBriefSuccess,
  BriefConfiguratorSetInitialValues,
  BriefConfiguratorSetValues
} from "../briefConfigurator/action";
import {
  BriefConfiguratorInitialValues,
  BriefConfiguratorState
} from "../briefConfigurator/reducer";
import { getBriefConfiguratorState } from "../briefConfigurator/selector";
import {
  BriefElementConfiguratorBlur,
  BriefElementConfiguratorClear,
  BriefElementConfiguratorIsValid,
  BriefElementConfiguratorSectionExpand,
  BriefElementConfiguratorSetInitialValues,
  BriefElementConfiguratorSetProductCategorySelected,
  BriefElementConfiguratorSetProductSelected,
  BriefElementConfiguratorSetProductVisibilityState,
  BriefElementConfiguratorSetValues,
  BriefElementSetSectionTouched
} from "../briefElementConfigurator/action";
import { BriefElementConfiguratorInitialValues } from "../briefElementConfigurator/constant";
import {
  BriefElementConfiguratorState,
  BriefElementConfiguratorValues,
  BriefElementState
} from "../briefElementConfigurator/reducer";
import { getBriefElementsConfiguratorState } from "../briefElementConfigurator/selector";
import { CompanyAddMany } from "../companies/action";
import {
  ConfiguratorInputOptionsResult,
  getConfiguratorInputOptionsResult
} from "../configurator-input-options/selector";
import { SectionNames } from "../configurator-inputs/constant";
import { LinerName } from "../liners/entity";
import { getLinerIdsByName } from "../liners/selector";
import { fetchProductCategorys } from "../productCategory/saga";
import * as ProductAPI from "../products/api";
import { Product } from "../products/entity";
import { ProductActions } from "../products/reducer";
import { getProductById } from "../products/selector";
import {
  ProjectAddMany,
  ProjectAssignBrief,
  ProjectUnAssignBrief
} from "../projects/action";
import { getProjectContext } from "../projects/selector";
import { RootStateQuery } from "../store";
import { UserAddMany } from "../users/action";
import { ProductSubCategory } from "./../../services/subcategory/types";
import { BriefElementConfiguratorSetProductSelectedScale } from "./../briefElementConfigurator/action";
import { computeProductScale3D, InitialScale3D } from "./../products/entity";
import {
  BriefActionsTypes,
  BriefAssignProject,
  BriefAssignProjectStatus,
  BriefCreateStatus,
  BriefDuplicateAndEdit,
  BriefEditName,
  BriefEditNameStatus,
  BriefFetchStatus,
  BriefFinalizeOrder,
  BriefForUserFetchStatus,
  BriefGetByIdStatus,
  BriefGetByIdWithRelations,
  BriefSelected,
  BriefUpdate,
  BriefUpdateStatus
} from "./action";
import * as Api from "./api";

/* Redistribute sub entities */
type RedistributeTypes = {
  briefs: Array<Brief>;
  projects: Array<Project>;
  companies: Array<Company>;
  users: Array<User>;
};
export function* briefRedistribution(
  briefs: Array<Brief>
): Generator<any, Array<Brief>, undefined> {
  const r = briefs.reduce(
    (p, c) => {
      if (c.project) {
        if (c.project.company) p.companies.push(c.project.company);
        p.projects.push(c.project);
      }
      if (c.user) p.users.push(c.user);
      p.briefs.push(omit(c, "user", "project"));
      return p;
    },
    {
      briefs: [],
      projects: [],
      companies: [],
      users: []
    } as RedistributeTypes
  );
  yield put(new ProjectAddMany(r.projects));
  yield put(new CompanyAddMany(r.companies));
  yield put(new UserAddMany(r.users));
  return r.briefs;
}

export function* fetchBriefs() {
  yield put(new BriefFetchStatus(FetchingStatus.PENDING));
  try {
    const { data: briefs } = yield call(Api.fetchBriefs);
    // const filteredBriefs = yield briefRedistribution(briefs as Array<Brief>);
    yield put(new BriefFetchStatus(FetchingStatus.SUCCESS, briefs));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new BriefFetchStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("briefs:createFailed")));
  }
}

export function* updateBriefByIdWithRelations(id: string) {
  try {
    const { data: briefSelected } = yield call(
      Api.getBriefByIdWithPrediction,
      id
    );
    yield put(new BriefGetByIdStatus(FetchingStatus.SUCCESS, briefSelected));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:getByIdFailed");
      scope.setContext("id", { id });
      Sentry.captureException(error);
    });
    yield put(new BriefGetByIdStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("briefs:getByIdFailed")));
  }
}

export function* getBriefByIdWithRelations(action: BriefGetByIdWithRelations) {
  yield put(new BriefGetByIdStatus(FetchingStatus.PENDING));
  yield call(updateBriefByIdWithRelations, action.id);
}

export function* createBriefWithRelation() {
  yield put(new BriefCreateStatus(FetchingStatus.PENDING));
  const briefElementsConfiguratorState: BriefElementConfiguratorState = yield select(
    getBriefElementsConfiguratorState
  );
  const briefConfiguratorState: BriefConfiguratorState = yield select(
    getBriefConfiguratorState
  );
  const contextProjectId: string | undefined = yield select(getProjectContext);

  const briefElementsValues = [];
  for (const [key, briefElement] of Object.entries(
    briefElementsConfiguratorState
  )) {
    if (!briefElement) continue;
    const configuratorInputOptions: BriefElementState = yield select(
      getConfiguratorInputOptionsResult(briefElement.productSelected, key)
    );
    const whiteTopLinerIds: string[] = yield select(
      getLinerIdsByName([LinerName.WHITE_TOP_KRAFT, LinerName.WHITE_TOP_TEST])
    );

    const validateValues = BriefElementConfiguratorSchema.validateSync(
      briefElement.values,
      {
        context: {
          productSelected: briefElement.productSelected,
          productCategorySelected: briefElement.productCategorySelected,
          configuratorInputOptions,
          whiteTopLinerIds
        }
      }
    );
    briefElementsValues.push({
      ...validateValues,
      position: Number(key),
      laminationId:
        briefElement.values.polyesterLaminationId ||
        briefElement.values.laminationId
    });
  }
  const configuratorValues = {
    briefElements: briefElementsValues,
    ...briefConfiguratorState.values,
    projectId: contextProjectId || briefConfiguratorState.values.projectId
  };
  try {
    const { data: briefCreated } = yield call(
      Api.createBrief,
      configuratorValues
    );
    yield put(new BriefSelected(briefCreated));
    yield put(new BriefCreateStatus(FetchingStatus.SUCCESS, briefCreated));
    if (briefCreated.projectId) {
      yield put(
        new ProjectAssignBrief(briefCreated.id, briefCreated.projectId)
      );
    }

    const isPricing: boolean = yield select(
      (state: RootStateQuery) => state.appSettings.setting.features?.isPricing
    );

    if (!briefCreated.hasPrice && isPricing) {
      const isGuest: boolean = yield select(getIsGuestUser);
      const isPackitoo: boolean = yield select(getIsPackitooUser);
      if (isGuest) {
        yield put(new BriefConfiguratorOpenRequestQuotationNoPrice(true));
      } else {
        yield put(new BriefConfiguratorOpenSaveBriefSuccess(true));
      }

      yield delay(400);
      if (isPackitoo && briefCreated._errorMessage) {
        yield put(
          new AppAddSnackbar(
            `${i18n.t("saga:alicia.error")}: ${getAliciaMessage(
              briefCreated._errorMessage
            )}`,
            "warning",
            { autoHideDuration: 10000 }
          )
        );
      } else {
        yield put(
          new AppAddSnackbar(i18n.t("saga:brief.create-success"), "success")
        );
      }
    } else if (!briefCreated.hasPrice && !isPricing) {
      yield put(new BriefConfiguratorOpenRequestHipeFirst(true));
      yield delay(400);
      yield put(
        new AppAddSnackbar(i18n.t("saga:brief.create-success"), "success")
      );
    } else {
      yield put(new BriefConfiguratorOpenPredictionPanel(true));
      yield delay(400);
      yield put(
        new AppAddSnackbar(i18n.t("saga:brief.create-success"), "success")
      );
    }
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:createFailed");
      scope.setContext("configuratorValues", { ...configuratorValues });
      Sentry.captureException(error);
    });
    yield put(new BriefCreateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("briefs:createFailed")));
  }
}

function* cleanBriefElementValues(
  briefElementsValues: BriefElementConfiguratorValues,
  product: Product,
  index: number
) {
  const configuratorInputOptions: ConfiguratorInputOptionsResult = yield select(
    getConfiguratorInputOptionsResult(product, index.toString())
  );
  const briefElementsValuesUpdated = { ...briefElementsValues };

  //TODO fix the following feature but at this point configuratorInputOptions can be empty if some fetches haven't been done yet

  // Scoped to new inputs for now, not the ones in hardcoded precondition cleaning/default value useEffects
  const inputToCheck: Array<keyof BriefElementConfiguratorValues> = [
    // ConfiguratorInputNames.TOTAL_RULE_LENGTH
  ];

  for (const input of inputToCheck) {
    // Clear values of inputs no more linked
    if (
      !!briefElementsValuesUpdated[input] &&
      !configuratorInputOptions[input]?.linked
    ) {
      const defaultValue = BriefElementConfiguratorInitialValues[input];
      briefElementsValuesUpdated[input] = defaultValue as any;
    }
  }
  return briefElementsValuesUpdated;
}

// TODO bad method, we should get only brief without relation here
// to avoid this transformation
function* transformValues(
  briefElement: BriefElementWithRelations,
  product: Product,
  index: number
) {
  const briefElementValues = reduce(
    briefElement,
    (result, value, key: any) => {
      // if (result.hasOwnProperty(key)) {
      if (has(result, key)) {
        result[key] = value;
      }
      return result;
    },
    Object.assign({}, BriefElementConfiguratorInitialValues) as any
  ) as BriefElementConfiguratorValues;
  //Flexo
  briefElementValues.directToneParamsRecto = transformInkParameters(
    briefElement.directToneParamsRecto
  );
  briefElementValues.directToneParamsVerso = transformInkParameters(
    briefElement.directToneParamsVerso
  );
  briefElementValues.cmykParamsRecto = transformInkParameters(
    briefElement.cmykParamsRecto
  );
  briefElementValues.cmykParamsVerso = transformInkParameters(
    briefElement.cmykParamsVerso
  );
  //Offset
  briefElementValues.directToneParamsOffsetRecto = transformInkParameters(
    briefElement.directToneParamsOffsetRecto
  );
  briefElementValues.directToneParamsOffsetVerso = transformInkParameters(
    briefElement.directToneParamsOffsetVerso
  );
  briefElementValues.cmykParamsOffsetRecto = transformInkParameters(
    briefElement.cmykParamsOffsetRecto
  );
  briefElementValues.cmykParamsOffsetVerso = transformInkParameters(
    briefElement.cmykParamsOffsetVerso
  );
  //ScreenPrinting
  briefElementValues.directToneParamsScreenPrintingRecto = transformInkParameters(
    briefElement.directToneParamsScreenPrintingRecto
  );
  briefElementValues.directToneParamsScreenPrintingVerso = transformInkParameters(
    briefElement.directToneParamsScreenPrintingVerso
  );
  //Digital
  briefElementValues.cmykwParamsDigitalVerso = transformInkParameters(
    briefElement.cmykwParamsDigitalVerso
  );
  briefElementValues.cmykwParamsDigitalRecto = transformInkParameters(
    briefElement.cmykwParamsDigitalRecto
  );

  if (
    briefElement.subCategoriesIds &&
    briefElement.subCategoriesIds.length > 0
  ) {
    briefElementValues.subCategoriesIds = briefElement.subCategoriesIds;
  }

  if (
    briefElementValues.dieCutToolMachineIds &&
    briefElementValues.dieCutToolMachineIds.length === 0
  ) {
    briefElementValues.dieCutToolMachineIds = undefined;
  }
  //Varnishes
  briefElementValues.varnishParamsOffsetRecto = transformVarnishParams(
    briefElement.varnishParamsOffsetRecto
  );
  briefElementValues.varnishParamsOffsetVerso = transformVarnishParams(
    briefElement.varnishParamsOffsetVerso
  );
  briefElementValues.luxuryVarnishParamsRecto = transformLuxuryvarnishParams(
    briefElement.luxuryVarnishParamsRecto
  );
  briefElementValues.luxuryVarnishParamsVerso = transformLuxuryvarnishParams(
    briefElement.luxuryVarnishParamsVerso
  );

  const briefElementsValuesCleaned: BriefElementConfiguratorValues = yield cleanBriefElementValues(
    briefElementValues,
    product,
    index
  );

  return briefElementsValuesCleaned;
}

export function* duplicateAndEditBrief(action: BriefDuplicateAndEdit): any {
  let isNotAllowed;
  let intersectionSubCategories: string[] | undefined;
  yield put(new BriefConfiguratorClear());
  yield put(new BriefElementConfiguratorClear());

  const currentUser: User = yield select(getCurrentUser);

  const { data: brief }: { data: BriefWithRelations } = yield call(
    Api.getBriefByIdWithPrediction,
    action.brief.id
  );

  yield putResolve(ProductActions.async.read() as any);
  yield fetchProductCategorys();
  const productCategories: ProductCategory[] = yield select(
    (state: RootState) => state.productsCategory.productsCategory
  );

  const subCategories: ProductSubCategory[] = yield select(
    (state: RootStateQuery) =>
      state.api.queries["getSubCategories(false)"]?.data || []
  );

  const briefElementsSorted = sortBy(brief.briefElements, (be) => be.position);
  for (let index = 0; index < briefElementsSorted.length; index++) {
    const productId = briefElementsSorted[index].productId;
    const product: Product | undefined = yield select(
      getProductById(productId || "")
    );

    if (!product || !product.enabled) {
      let productArchived;
      if (!product) {
        const { data } = yield call(ProductAPI.getArchivedById, {
          productId
        });
        productArchived = data;
      }
      const lang: string = yield select(getLanguage);
      const productName = product?.label
        ? jsonTranslator(product?.label, lang)
        : productArchived?.label
        ? jsonTranslator(productArchived?.label, lang)
        : `#${index + 1}`;

      productArchived
        ? yield put(
            new AppAddSnackbar(
              i18n.t("saga:products.isArchived", {
                brief: brief.code!
              }),
              "warning",
              { autoHideDuration: 15000 }
            )
          )
        : yield put(
            new AppAddSnackbar(
              i18n.t("saga:products.notEnable", {
                name: productName
              }),
              "warning",
              { autoHideDuration: 15000 }
            )
          );

      continue;
    }

    if (product?.isQuantityOpen) {
      yield put(
        new BriefElementConfiguratorSectionExpand(
          "0",
          SectionNames.PURCHASE_CONDITION,
          true
        )
      );
    }

    if (currentUser!.roleIds.length > 0) {
      isNotAllowed = product!.roleIds?.includes(currentUser!.roleIds[0]);
    } else {
      isNotAllowed = !product!.isAllowingGuest;
    }

    const briefElementsValuesCleaned = yield transformValues(
      briefElementsSorted[index],
      product,
      index
    );

    const briefElementsValues = merge(
      {},
      BriefElementConfiguratorInitialValues,
      briefElementsValuesCleaned
    );

    // Create an intersection of products, brief & subcategories
    // eg: ( ["E", "R", "P", "L"], ["D", "R", "L"], ["R", "P", "D", "L"] => return intersection ["R", "L"] )
    if (
      briefElementsValues.subCategoriesIds &&
      briefElementsValues.subCategoriesIds.length > 0 &&
      product
    ) {
      // Put the smallest array in the index 0 to increase performance during the reduce O(nlogn) instead of O(n^2)
      const subCategoriesTotal = [
        briefElementsValues.subCategoriesIds,
        product.subCategoriesIds,
        subCategories.map((item) => item.id)
      ];

      intersectionSubCategories = subCategoriesTotal.reduce((a, b) =>
        a?.filter((c: any) => b?.includes(c))
      );
    }

    if (intersectionSubCategories && intersectionSubCategories.length > 0) {
      briefElementsValues.subCategoriesIds = intersectionSubCategories;

      briefElementsValues.subCategories = subCategories
        .filter((item: ProductSubCategory) =>
          (intersectionSubCategories as string[]).includes(item.id)
        )
        .map((item) => item.label);
    }

    if (intersectionSubCategories && intersectionSubCategories.length === 0) {
      briefElementsValues.subCategoriesIds = [];
      briefElementsValues.subCategories = [];
    }

    yield put(
      new BriefElementConfiguratorSetInitialValues(
        index.toString(),
        briefElementsValues
      )
    );
    yield put(
      new BriefElementConfiguratorSetValues(
        index.toString(),
        briefElementsValues
      )
    );
    yield put(new BriefElementConfiguratorBlur(index.toString()));
    yield put(
      new BriefElementConfiguratorSetProductCategorySelected(
        index.toString(),
        productCategories.find(
          (p: ProductCategory) => p.id === briefElementsValues.productCategoryId
        )
      )
    );

    yield put(
      new BriefElementConfiguratorSetProductSelected(index.toString(), product)
    );
    yield put(
      new BriefElementConfiguratorSetProductSelectedScale(
        index.toString(),
        product.modelResizing
          ? computeProductScale3D(product, briefElementsValues)
          : InitialScale3D
      )
    );

    yield put(
      new BriefElementConfiguratorSetProductVisibilityState(
        index.toString(),
        isNotAllowed
      )
    );

    yield put(new BriefElementConfiguratorIsValid(index.toString(), true));
    yield put(new BriefElementSetSectionTouched(index.toString()));
  }
  const briefValues = merge(
    {
      name: brief.name || undefined,
      deliveryZipCode: brief.deliveryZipCode || undefined,
      intercom: brief.intercom || undefined,
      projectId: brief.projectId || undefined,
      parentId: brief?.id,
      rootId: brief?.rootId ?? brief?.id
    },
    {
      ...BriefConfiguratorInitialValues,
      context: ConfiguratorContext.DUPLICATION
    }
  );

  yield put(new BriefConfiguratorSetInitialValues(briefValues));
  yield put(new BriefConfiguratorSetValues(briefValues));
  yield put(push("/configurator?duplicate"));
  yield put(
    new AppAddSnackbar(
      i18n.t("briefs:duplicateAndEdit", {
        id: brief.code
      }),
      "info"
    )
  );
}

export function* editBriefName(action: BriefEditName) {
  yield put(new BriefEditNameStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.editBriefName, action.values);
    yield put(new BriefEditNameStatus(FetchingStatus.SUCCESS, action.values));
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:editBriefName");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new BriefEditNameStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* update(action: BriefUpdate) {
  yield put(new BriefUpdateStatus(FetchingStatus.PENDING));
  try {
    const { data: updatedBrief } = yield call(
      Api.update,
      action.briefId,
      action.partialBrief
    );
    yield put(new BriefUpdateStatus(FetchingStatus.SUCCESS, updatedBrief));
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:update");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new BriefUpdateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* finalizeOrderBrief(action: BriefFinalizeOrder) {
  yield put(new BriefForUserFetchStatus(FetchingStatus.PENDING));
  try {
    const {
      data: { redirection }
    } = yield call(Api.briefToCart, action.brief.id, action.cmsType);
    window.open(redirection, "_self");
    yield put(new BriefForUserFetchStatus(FetchingStatus.SUCCESS));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:finalizeOrder");
      Sentry.captureException(error);
    });
    yield put(new BriefForUserFetchStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
    if (action.setIsFinalisingOrder) {
      // Stop loader when back return error
      action.setIsFinalisingOrder(false);
    }
  }
}

export function* assingBriefProject(action: BriefAssignProject) {
  yield put(new BriefAssignProjectStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.assignProject, action.brief.id, action.projectId);
    yield put(
      new BriefAssignProjectStatus(
        FetchingStatus.SUCCESS,
        action.brief.id,
        action.projectId
      )
    );
    if (action.projectId)
      yield put(new ProjectAssignBrief(action.brief.id, action.projectId));
    if (action.brief.projectId)
      yield put(
        new ProjectUnAssignBrief(action.brief.id, action.brief.projectId)
      );
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:assingBriefProject");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new BriefAssignProjectStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* fetchBriefsForUser() {
  yield put(new BriefForUserFetchStatus(FetchingStatus.PENDING));
  const currentUser: User = yield select(getCurrentUser);
  try {
    const { data: briefs } = yield call(Api.readForUser, currentUser.id);
    // const filteredBriefs = yield briefRedistribution(briefs as Array<Brief>);

    yield put(new BriefForUserFetchStatus(FetchingStatus.SUCCESS, briefs));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("briefs:fetchForUserFailed");
      Sentry.captureException(error);
    });
    yield put(new BriefForUserFetchStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:briefs.fetchForUserFailed"))
    );
  }
}

export const BriefSaga = [
  takeLatest(BriefActionsTypes.BRIEF_FETCH, fetchBriefs),
  takeLatest(BriefActionsTypes.BRIEF_CREATE, createBriefWithRelation),
  takeLatest(BriefActionsTypes.BRIEF_GET_BY_ID, getBriefByIdWithRelations),
  takeLatest(BriefActionsTypes.BRIEF_DUPLICATE_AND_EDIT, duplicateAndEditBrief),
  takeLatest(BriefActionsTypes.BRIEF_EDIT_NAME, editBriefName),
  takeLatest(BriefActionsTypes.BRIEF_UPDATE, update),
  takeLatest(BriefActionsTypes.BRIEF_FINALIZE_ORDER, finalizeOrderBrief),
  takeLatest(BriefActionsTypes.BRIEF_ASSIGN_PROJECT, assingBriefProject),
  takeLatest(BriefActionsTypes.BRIEF_FOR_USER_FETCH, fetchBriefsForUser)
];
