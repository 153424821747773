import * as Sentry from "@sentry/react";
import { push } from "connected-react-router";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "..";
import { RoleStatus } from "../../entities/role";
import { User } from "../../entities/user";
import i18n from "../../utils/i18n";
import { removeToken, saveLanguage, saveToken } from "../../utils/reconnection";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { AppAddSnackbar, AppRedirectAfterSubmit } from "../app/action";
import {
  AuthenticationActionsTypes,
  LogOutStatusAction,
  RetryConnectionAction,
  RetryConnectionStatusAction,
  SignInFetchAction,
  SignInStatusAction,
  SignUpAction,
  SignUpStatusAction,
  UserChangePassword,
  UserChangePasswordStatus
} from "./action";
import * as Api from "./api";
import { findUserRoleStatus } from "./selector";
import { selectCompanyOnLogin } from "../contact/contact.slice";

const filteredUserData = (user: User) => ({
  id: user.id,
  username: user.username,
  email: user.email
});

const getRedirection = (user: User, custom?: string) => {
  const roleState = findUserRoleStatus(user);
  let path = "/configurator";
  switch (roleState) {
    case RoleStatus.IS_ADMIN:
      path = custom ?? "/back-office/projects";
      break;
    case RoleStatus.IS_EXTERNAL_SALES:
    case RoleStatus.IS_GUEST:
      path = "/back-office/my-projects";
      break;
  }
  return path;
};

export function* retryConnection(action: RetryConnectionAction) {
  const { token, redirection } = action;
  yield put(new RetryConnectionStatusAction(FetchingStatus.PENDING));
  try {
    const { data: user } = yield call(Api.RetryConnection, token);
    Sentry.setUser(filteredUserData(user));
    yield put(new RetryConnectionStatusAction(FetchingStatus.SUCCESS, user));
    yield put(push(redirection || getRedirection(user)));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("authentication:retryConnection");
      Sentry.captureException(error);
    });
    removeToken();
    yield put(new RetryConnectionStatusAction(FetchingStatus.FAILED));
    yield put(push("/"));
    sendErrorNotification(error, i18n.t("authentication:retryConnection"));
  }
}

export function* signin(action: SignInFetchAction) {
  const {
    user: { username: usernameFromAction, password: passwordFromAction },
    onAfterSubmit
  } = action;

  yield put(new SignInStatusAction(FetchingStatus.PENDING));
  try {
    const redirect: string = yield select(
      (state: RootState) => state.app.redirectAfterSubmit
    );

    const { data: user } = yield call(
      Api.signin,
      usernameFromAction,
      passwordFromAction
    );

    Sentry.setUser(filteredUserData(user));
    yield put(new SignInStatusAction(FetchingStatus.SUCCESS, user));
    saveToken(user.token);

    // Set default language on signin
    if (user.defaultLanguage) {
      saveLanguage(user.defaultLanguage);
    }

    yield put(selectCompanyOnLogin(user));

    if (onAfterSubmit) {
      return onAfterSubmit(user);
    }

    yield put(push(redirect || getRedirection(user, action.custom)));

    if (redirect) yield put(new AppRedirectAfterSubmit(undefined));
    else
      yield put(
        new AppAddSnackbar(i18n.t("authentication:signInSuccess"), "success")
      );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("authentication:signInFailed");
      scope.setContext("user", { username: action.user.username });
      Sentry.captureException(error);
    });
    yield put(new SignInStatusAction(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("authentication:signInFailed"))
    );
  }
}

export function* signup(action: SignUpAction) {
  yield put(new SignUpStatusAction(FetchingStatus.PENDING));
  const { dto, onAfterSubmit } = action;
  try {
    const { data: user } = yield call(Api.signup, dto);
    Sentry.setUser(filteredUserData(user));
    yield put(new SignUpStatusAction(FetchingStatus.SUCCESS, user));
    saveToken(user.token);
    if (onAfterSubmit) {
      return onAfterSubmit(user);
    }
    yield put(push("/back-office/my-projects"));
    yield put(
      new AppAddSnackbar(i18n.t("authentication:signUpSuccess"), "success")
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("authentication:signUpFailed");
      scope.setContext("user", { username: action });
      Sentry.captureException(error);
    });
    yield put(new SignInStatusAction(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("authentication:signInFailed"))
    );
  }
}

export function* logout() {
  removeToken();
  Sentry.setUser(null);
  yield put(new LogOutStatusAction(FetchingStatus.SUCCESS));
  yield put(push("/"));
}

export function* changePassword(action: UserChangePassword) {
  yield put(new UserChangePasswordStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.changePassword, action.token, action.qPath, action.password);

    yield put(
      new AppAddSnackbar(
        i18n.t("authentication:changePasswordSuccess"),
        "success",
        { autoHideDuration: 3000 }
      )
    );
    yield put(new UserChangePasswordStatus(FetchingStatus.SUCCESS));
    yield put(push("/"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("authentication:changePasswordFailed");
      scope.setContext("user", { username: action });
      Sentry.captureException(error);
    });
    yield put(new UserChangePasswordStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(
        error,
        i18n.t("authentication:changePasswordFailed")
      )
    );
  }
}

export const AuthenticationSaga = [
  takeLatest(AuthenticationActionsTypes.RETRY_CONNECTION, retryConnection),
  takeLatest(AuthenticationActionsTypes.SIGNIN_FETCH, signin),
  takeLatest(AuthenticationActionsTypes.SIGNUP, signup),
  takeLatest(AuthenticationActionsTypes.LOG_OUT_FETCH, logout),
  takeLatest(AuthenticationActionsTypes.USER_CHANGE_PASSWORD, changePassword)
];
