import { FormikProps } from "formik";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { BriefElementConfiguratorValues } from "../../../../../../reducers/briefElementConfigurator/reducer";
import { ConfiguratorInputOptionSelector } from "../../../../../../reducers/configurator-input-options/selector";
import { Product } from "../../../../../../reducers/products/entity";
import { usePrevious } from "../../../../../../utils/hooks/usePrevious";
import { useUpdateEffect } from "../../../../../../utils/hooks/useUpdateEffect";

export const WindowInputs = [
  // "addWindow",
  "windowHeight",
  "windowHeight",
  "windowWidth",
  "windowId",
  "windowWantProtection"
];

export interface DecorationsCorrugatedInputOptionsProps
  extends FormikProps<BriefElementConfiguratorValues> {
  objectKey: string;
  productSelected?: Product;
}

export const WindowInputOptions: React.FC<DecorationsCorrugatedInputOptionsProps> = memo(
  ({ productSelected, objectKey, ...formikProps }) => {
    const { values, setFieldValue } = formikProps;
    const { addWindow, windowWantProtection } = values;
    const inputOpt = useSelector(
      ConfiguratorInputOptionSelector.getConfiguratorInputOptionsResult(
        productSelected,
        objectKey
      )
    );
    const addWindowPrevious = usePrevious(addWindow);
    const windowWantProtectionPrevious = usePrevious(windowWantProtection);
    // const productSelectedPrevious: Product | undefined = usePrevious(
    //   productSelected
    // );

    // useEffect(() => {
    //   if (
    //     productSelectedPrevious &&
    //     productSelected &&
    //     productSelected.id !== productSelectedPrevious.id
    //   ) {
    //     setFieldValue("addWindow", inputOpt.addWindow?.options?.default);
    //   }
    // }, [inputOpt, productSelected, productSelectedPrevious, setFieldValue]);

    useUpdateEffect(() => {
      if (addWindowPrevious === addWindow) {
        return;
      }

      if (!addWindow) {
        setFieldValue("windowHeight", undefined);
        setFieldValue("windowWidth", undefined);
        setFieldValue("windowWantProtection", undefined);
      } else {
        setFieldValue("windowHeight", inputOpt.windowHeight?.options?.default);
        setFieldValue("windowWidth", inputOpt.windowWidth?.options?.default);
        setFieldValue(
          "windowWantProtection",
          inputOpt.windowWantProtection?.options?.default
        );
      }
    }, [addWindow, addWindowPrevious, inputOpt, setFieldValue]);

    useUpdateEffect(() => {
      if (windowWantProtectionPrevious === windowWantProtection) {
        return;
      }

      if (!windowWantProtection) {
        setFieldValue("windowId", undefined);
      } else {
        setFieldValue("windowId", inputOpt.windowId?.options?.default);
      }
    }, [
      inputOpt,
      setFieldValue,
      windowWantProtection,
      windowWantProtectionPrevious
    ]);

    return null;
  }
);
