import { Box } from "@material-ui/core";
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { MyButton, MyButtonProps } from "../MyButton";
import { MyDialog } from "../MyDialog";
import { Page } from "../MyPage";
import { MyTypography } from "../MyTypography";
import BriefImg from "./assets/briefs.svg";
import CompanyImg from "./assets/companies.svg";
import ContactImg from "./assets/contacts.svg";
import TaskImg from "./assets/task.svg";
import ContactSmallImg from "./assets/contacts_small.svg";
import CofnImg from "./assets/cofn.svg";
import ReportImg from "./assets/report.svg";
import {
  default as FileImg,
  default as ProjectImg
} from "./assets/projects.svg";
import ProjectSmallImg from "./assets/projects_small.svg";
import Lightbulb from "@material-ui/icons/EmojiObjectsOutlined";
import { MyAlert } from "../MyAlert";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { MyLink } from "../MyLink";
import NotificationInbox from "./assets/notification_inbox.svg";
import NotificationArchived from "./assets/notification_archived.svg";
import NotificationFollowing from "./assets/notification_following.svg";

export enum EmptyStateContext {
  COMPANY = "COMPANY",
  PROJECT = "PROJECT",
  CONTACT = "CONTACT",
  FILE = "FILE",
  BRIEF = "BRIEF",
  CORRUGATED_MATERIAL = "CORRUGATED_MATERIAL",
  CORRUGATED_LINER = "CORRUGATED_LINER",
  CORRUGATED_FLUTE = "CORRUGATED_FLUTE",
  CORRUGATED_SUPPLIER = "CORRUGATED_SUPPLIER",
  CORRUGATED_PRICE = "CORRUGATED_PRICE",
  CORRUGATED_FORMAT = "CORRUGATED_FORMAT",
  CORRUGATED_COMPOSITION = "CORRUGATED_COMPOSITION",
  SUBMISSIONS = "SUBMISSIONS",
  FOLDING_MATERIAL_TYPE = "FOLDING_MATERIAL_TYPE",
  FOLDING_MATTER = "FOLDING_MATTER",
  FOLDING_PRICE = "FOLDING_PRICE",
  TASKS = "TASKS",
  FLEXO_PLATE = "FLEXO_PLATE",
  MACHINE_OPERATION_FORMULA = "MACHINE_OPERATION_FORMULA",
  REPORTS = "REPORTS",
  EXPORTS = "EXPORTS",
  IMPORTS = "IMPORTS",
  IN_APP_NOTIFICATIONS_INBOX = "IN_APP_NOTIFICATIONS_INBOX",
  IN_APP_NOTIFICATIONS_ARCHIVED = "IN_APP_NOTIFICATIONS_ARCHIVED",
  IN_APP_NOTIFICATIONS_FOLLOWING = "IN_APP_NOTIFICATIONS_FOLLOWING"
}

export interface EmptyStateProps {
  variant?: "initial" | "small";
  formComponent?: ReactNode;
  dialogComponent?: ReactNode;
  actionComponent?: (open: Dispatch<SetStateAction<boolean>>) => ReactNode;
  context: EmptyStateContext;
  onClick?: () => void;
  buttonProps?: MyButtonProps;
  disableAction?: boolean;
  containerStyle?: React.CSSProperties;
}

export const EmptyState: FC<EmptyStateProps> = ({
  variant = "initial",
  formComponent,
  dialogComponent,
  actionComponent,
  context,
  onClick,
  buttonProps,
  disableAction = false,
  containerStyle
}) => {
  const { t } = useTranslation("components");
  const { t: tCommon } = useTranslation("common");
  const { t: tBackOffice } = useTranslation("backoffice");
  const [openForm, setOpenForm] = useState(false);
  const { isCollectionOfNeeds, isConfigurator } = useSelector(
    (state: RootState) => ({
      isCollectionOfNeeds:
        state.appSettings.setting.features?.isCollectionOfNeeds,
      isConfigurator: state.appSettings.setting.features?.isConfigurator
    })
  );
  const [
    img,
    title,
    content,
    action,
    dialogTitle,
    infoTitle,
    infoBody,
    infoDetails
  ]: any = useMemo(() => {
    const features1: JSX.Element[] = [];
    const features2: JSX.Element[] = [];
    const features3: JSX.Element[] = [];
    switch (context) {
      case EmptyStateContext.COMPANY:
        return [
          CompanyImg,
          t("emptyState.companies.title"),
          t("emptyState.companies.content"),
          t("emptyState.companies.action"),
          tBackOffice("companies.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.PROJECT:
        if (isConfigurator) {
          features1.push(t("emptyState.projects.features1.configurator"));
          features2.push(t("emptyState.projects.features2.configurator"));
          features3.push(
            <MyLink to="/back-office/my-configs">
              {t("emptyState.projects.features3.configurator")}
            </MyLink>
          );
        }
        if (isCollectionOfNeeds) {
          features1.push(t("emptyState.projects.features1.cofn"));
          features2.push(t("emptyState.projects.features2.cofn"));
          features3.push(
            <MyLink to="/back-office/my-collection-of-needs">
              {t("emptyState.projects.features3.cofn")}
            </MyLink>
          );
        }
        return [
          variant === "initial" ? ProjectImg : ProjectSmallImg,
          t("emptyState.projects.title"),
          t("emptyState.projects.content"),
          t("emptyState.projects.action"),
          tBackOffice("projects.form.create.title"),
          t("emptyState.projects.infoTitle"),
          t("emptyState.projects.infoBody", {
            features1: features1.join(", "),
            interpolation: { escapeValue: false }
          }),
          <MyTypography key="details">
            {t("emptyState.projects.infoDetails", {
              features2: features2.join(` ${tCommon("or")} `),
              interpolation: { escapeValue: false }
            })}{" "}
            {features3.map((feature, index) => (
              <React.Fragment key={index}>
                {index === 0 ? "" : ", "}
                {feature}
              </React.Fragment>
            ))}
            {"."}
          </MyTypography>
        ];
      case EmptyStateContext.CONTACT:
        return [
          variant === "initial" ? ContactImg : ContactSmallImg,
          t("emptyState.contacts.title"),
          t("emptyState.contacts.content"),
          t("emptyState.contacts.action"),
          tBackOffice("contacts.form.create-title"),
          undefined,
          undefined
        ];
      case EmptyStateContext.FILE:
        return [
          FileImg,
          t("emptyState.files.title"),
          t("emptyState.files.content"),
          t("emptyState.files.action"),
          tBackOffice("files.form.create-title"),
          undefined,
          undefined
        ];
      case EmptyStateContext.BRIEF:
        return [
          BriefImg,
          t("emptyState.briefs.title"),
          t("emptyState.briefs.content"),
          t("emptyState.briefs.action"),
          tBackOffice("requests.createRequest"),
          undefined,
          undefined
        ];
      case EmptyStateContext.CORRUGATED_MATERIAL:
        return [
          ProjectSmallImg,
          t("emptyState.corrugated-materials.title"),
          t("emptyState.corrugated-materials.content"),
          t("emptyState.corrugated-materials.action"),
          tBackOffice("corrugated.materials.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.CORRUGATED_LINER:
        return [
          ProjectSmallImg,
          t("emptyState.corrugated-liners.title"),
          t("emptyState.corrugated-liners.content"),
          t("emptyState.corrugated-liners.action"),
          tBackOffice("corrugated.liners.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.CORRUGATED_FLUTE:
        return [
          ProjectSmallImg,
          t("emptyState.corrugated-flutes.title"),
          t("emptyState.corrugated-flutes.content"),
          t("emptyState.corrugated-flutes.action"),
          tBackOffice("corrugated.flutes.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.CORRUGATED_SUPPLIER:
        return [
          ProjectSmallImg,
          t("emptyState.corrugated-suppliers.title"),
          t("emptyState.corrugated-suppliers.content"),
          t("emptyState.corrugated-suppliers.action"),
          tBackOffice("corrugated.suppliers.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.CORRUGATED_COMPOSITION:
        return [
          ProjectSmallImg,
          t("emptyState.corrugated-compositions.title"),
          t("emptyState.corrugated-compositions.content"),
          t("emptyState.corrugated-compositions.action"),
          tBackOffice("corrugated.compositions.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.CORRUGATED_PRICE:
        return [
          ProjectSmallImg,
          t("emptyState.corrugated-prices.title"),
          t("emptyState.corrugated-prices.content"),
          t("emptyState.corrugated-prices.action"),
          tBackOffice("corrugated.prices.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.CORRUGATED_FORMAT:
        return [
          ProjectSmallImg,
          t("emptyState.corrugated-formats.title"),
          t("emptyState.corrugated-formats.content"),
          t("emptyState.corrugated-formats.action"),
          tBackOffice("corrugated.formats.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.SUBMISSIONS:
        return [
          CofnImg,
          t("emptyState.submissions.title"),
          t("emptyState.submissions.content"),
          t("emptyState.submissions.action"),
          "",
          undefined,
          undefined
        ];
      case EmptyStateContext.FOLDING_MATERIAL_TYPE:
        return [
          ProjectSmallImg,
          t("emptyState.folding-material-type.title"),
          t("emptyState.folding-material-type.content"),
          t("emptyState.folding-material-type.action"),
          tBackOffice("settings.materials.type.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.FOLDING_MATTER:
        return [
          ProjectSmallImg,
          t("emptyState.folding-matter.title"),
          t("emptyState.folding-matter.content"),
          t("emptyState.folding-matter.action"),
          tBackOffice("settings.materials.matter.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.FOLDING_PRICE:
        return [
          ProjectSmallImg,
          t("emptyState.folding-price.title"),
          t("emptyState.folding-price.content"),
          t("emptyState.folding-price.action"),
          tBackOffice("corrugated.prices.create")
        ];
      case EmptyStateContext.TASKS:
        return [
          TaskImg,
          t("emptyState.tasks.title"),
          t("emptyState.tasks.content"),
          t("emptyState.tasks.action"),
          tBackOffice("tasks.form.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.FLEXO_PLATE:
        return [
          ProjectSmallImg,
          t("emptyState.flexo-plate.title"),
          t("emptyState.flexo-plate.content"),
          t("emptyState.flexo-plate.action"),
          tBackOffice("flexo.plate.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.MACHINE_OPERATION_FORMULA:
        return [
          CofnImg,
          t("emptyState.machine-operation-formula.title"),
          t("emptyState.machine-operation-formula.content"),
          t("emptyState.machine-operation-formula.action"),
          tBackOffice("settings.machine-operation-formula.action.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.REPORTS:
        return [
          ReportImg,
          t("emptyState.reports.title"),
          t("emptyState.reports.content"),
          t("emptyState.reports.action"),
          tBackOffice("reports.action.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.EXPORTS:
        return [
          ReportImg,
          t("emptyState.exports.title"),
          t("emptyState.exports.content"),
          t("emptyState.exports.action"),
          tBackOffice("reports.action.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.IMPORTS:
        return [
          ReportImg,
          t("emptyState.imports.title"),
          t("emptyState.imports.content"),
          t("emptyState.imports.action"),
          tBackOffice("reports.action.import.create"),
          undefined,
          undefined
        ];
      case EmptyStateContext.IN_APP_NOTIFICATIONS_INBOX:
        return [
          NotificationInbox,
          t("emptyState.notifications.inbox.title"),
          t("emptyState.notifications.inbox.content"),
          undefined,
          undefined,
          undefined,
          undefined
        ];
      case EmptyStateContext.IN_APP_NOTIFICATIONS_ARCHIVED:
        return [
          NotificationArchived,
          t("emptyState.notifications.archived.title"),
          t("emptyState.notifications.archived.content"),
          undefined,
          undefined,
          undefined,
          undefined
        ];
      case EmptyStateContext.IN_APP_NOTIFICATIONS_FOLLOWING:
        return [
          NotificationFollowing,
          t("emptyState.notifications.following.title"),
          t("emptyState.notifications.following.content"),
          undefined,
          undefined,
          undefined,
          undefined
        ];
    }
  }, [
    context,
    isCollectionOfNeeds,
    isConfigurator,
    t,
    tBackOffice,
    tCommon,
    variant
  ]);

  return (
    <Page style={{ ...containerStyle }}>
      {dialogComponent || (
        <MyDialog
          titleLabel={dialogTitle}
          open={openForm}
          onClose={() => setOpenForm(false)}
          content={formComponent}
        />
      )}
      <Box
        pt={{ xs: 2, sm: 4 }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="flex-start"
          flexDirection="column"
        >
          {(infoTitle || infoBody) && (
            <MyAlert severity={"info"}>
              <Box flexDirection="column">
                <MyTypography variant="body2" bold>
                  {infoTitle}
                </MyTypography>
                <MyTypography variant="body2">{infoBody}</MyTypography>
              </Box>
            </MyAlert>
          )}
          {infoDetails && (
            <MyAlert severity={"info"} icon={Lightbulb} transparentBackground>
              <MyTypography variant="body2">{infoDetails}</MyTypography>
            </MyAlert>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <Box
            clone
            height={variant === "initial" ? 240 : 94}
            width="100%"
            maxWidth={340}
          >
            <img src={img} alt={""} />
          </Box>
        </Box>
        {variant === "initial" && (
          <MyTypography align="center" paragraph bold variant="h6">
            {title}
          </MyTypography>
        )}
        <MyTypography
          align="center"
          paragraph
          color="textSecondary"
          //Should never be setup from backoffice with this props
          dangerouslySetInnerHTML={{
            __html: variant === "small" ? title : content
          }}
        >
          {/* {variant === "small" ? title : content} */}
        </MyTypography>
        {disableAction ? null : actionComponent ? (
          actionComponent(setOpenForm)
        ) : (
          <MyButton
            capitalize
            size={variant === "initial" ? "large" : "medium"}
            variant="contained"
            color="primary"
            onClick={() => (onClick ? onClick() : setOpenForm(true))}
            {...buttonProps}
          >
            {action}
          </MyButton>
        )}
      </Box>
    </Page>
  );
};
