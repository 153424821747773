import { RootStateQuery, store } from "./../../reducers/store";
import { AxiosError } from "axios";
import { User } from "../../entities/user";
import { AppAddSnackbar } from "../../reducers/app/action";
import { apiSlice } from "../../utils/api/api";
import { dynamicUrl } from "../../utils/function/dynamicUrl";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { BriefPagination } from "../brief/types";
import { UpdateCurrentUser } from "../../reducers/authentication/action";
import { PageMetaDtoCrm } from "../common/types";
import { ContactContext, UserContacts, UserPagination } from "./types";
import {
  transformAccount,
  transformContact,
  transformContacts
} from "./transform";
import { transformBrief } from "../brief/transform";
import { GetSubmissionsResponse } from "../submission/types";
import { Submission } from "../../entities/submission";
import { AutoCompleteOption } from "../../components/common/form/MyAutocompleteField";
import { queryEntityOptions } from "../common/utils";
import {
  AutoCompleteOptionWRoles,
  getInvalidatTagsContacts,
  getUserOptions
} from "./utils";
import { StandardRoles, StandardRolesWGuest } from "../../entities/role";
import { createSelector } from "reselect";
import { File } from "../../reducers/files/entity";
import { UserChangePasswordReturnMessage, UserUpdateContext } from "./constant";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "Briefs",
    "Users",
    "MySubmissions",
    "ProjectSubmissions",
    "Submissions",
    "UserOptions",
    "MyContacts",
    "CompanyContacts",
    "ContactDetail"
  ]
});

export const extendedUserApiSlice = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getBriefByUserId: builder.query<BriefPagination, Partial<PageMetaDtoCrm>>({
      query: ({
        page = 1,
        limit = 25,
        userId,
        sort,
        status,
        price,
        search,
        companyId
      }) =>
        dynamicUrl(`/users/${userId}/briefs`, {
          page,
          limit,
          sort,
          status,
          price,
          search,
          companyId
        }),
      transformResponse: (responseData: BriefPagination) => {
        return transformBrief(responseData);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Briefs" as const,
                id
              })),
              { type: "Briefs", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Briefs", id: "PARTIAL-LIST" }]
    }),
    getUsers: builder.query<UserPagination, Partial<PageMetaDtoCrm>>({
      query: ({ page = 1, limit = 25, search, role, sort, filterId }) =>
        dynamicUrl(`/users/pagination`, {
          page,
          limit,
          role,
          search,
          sort,
          filterId
          /*  ...(getLocalEntityFilter(MetaEntityHipe.User) && {
            filterId: getLocalEntityFilter(MetaEntityHipe.User)
          }) */
        }),
      transformResponse: (responseData: UserPagination) => {
        const language = store.getState().app.language;
        return transformAccount(responseData, language);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Users" as const,
                id
              })),
              { type: "Users", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Users", id: "PARTIAL-LIST" }]
    }),
    getInternals: builder.query<User[], void>({
      query: () => `/users/internals`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Users" as const,
                id
              })),
              { type: "Users", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Users", id: "PARTIAL-LIST" }]
    }),
    getAllUsers: builder.query<User[], void>({
      query: () => `/users`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Users" as const,
                id
              })),
              { type: "Users", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Users", id: "PARTIAL-LIST" }]
    }),
    getAllUserOptions: builder.query<
      AutoCompleteOptionWRoles[],
      StandardRolesWGuest[] | undefined
    >({
      ...queryEntityOptions({
        // add managedProjects join for the case where an internal sales assign a brief to an external sales
        // url: "/users?join=managedProjects",
        url: "/users/managed-external-sales", //returns internal users and external sales
        type: "UserOptions",
        transform: getUserOptions
      })
    }),
    getAllUserWExternalSalesManagementsOptions: builder.query<
      AutoCompleteOptionWRoles[],
      StandardRoles[] | undefined
    >({
      ...queryEntityOptions({
        url: "/users/task-managed-external-sales", //returns internal users and external sales w management
        type: "UserOptions",
        transform: getUserOptions
      })
    }),
    getOneContact: builder.query<User | undefined, string>({
      query: (id) => `/users/${id}/contact`,
      providesTags: (result: User | undefined) => [
        { type: "ContactDetail", id: result?.id }
      ],
      transformResponse: transformContact
    }),
    getContacts: builder.query<User[], UserContacts>({
      query: () => `/users/contacts`
    }),
    getContactsPaginated: builder.query<
      UserPagination,
      Partial<PageMetaDtoCrm>
    >({
      query: ({ page = 1, limit = 25, search, sort, filterId }) =>
        dynamicUrl(`/users/contacts/pagination`, {
          page,
          limit,
          search,
          sort,
          filterId
          /* ...(getLocalEntityFilter(MetaEntityHipe.Contact) && {
            filterId: getLocalEntityFilter(MetaEntityHipe.Contact)
          }) */
        }),
      transformResponse: transformContacts,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Users" as const,
                id
              })),
              { type: "Users", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Users", id: "PARTIAL-LIST" }]
    }),
    getMyContactsPaginated: builder.query<
      UserPagination,
      Partial<PageMetaDtoCrm>
    >({
      query: ({ page = 1, limit = 25, search, sort, filterId }) =>
        dynamicUrl(`/users/my-contacts/pagination`, {
          page,
          limit,
          search,
          sort,
          filterId
          /* ...(getLocalEntityFilter(MetaEntityHipe.Contact) && {
            filterId: getLocalEntityFilter(MetaEntityHipe.Contact)
          }) */
        }),
      transformResponse: (responseData: UserPagination) => {
        return transformContacts(responseData);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "MyContacts" as const,
                id
              })),
              { type: "MyContacts", id: "PARTIAL-LIST" }
            ]
          : [{ type: "MyContacts", id: "PARTIAL-LIST" }]
    }),
    getCompanyContactPaginated: builder.query<
      UserPagination,
      Partial<PageMetaDtoCrm>
    >({
      query: ({ page = 1, limit = 25, search, sort, companyId, filterId }) =>
        dynamicUrl(`/companies/${companyId}/contacts`, {
          page,
          limit,
          search,
          sort,
          filterId
        }),
      transformResponse: (responseData: UserPagination) => {
        return transformContacts(responseData);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "CompanyContacts" as const,
                id
              })),
              { type: "CompanyContacts", id: "PARTIAL-LIST" }
            ]
          : [{ type: "CompanyContacts", id: "PARTIAL-LIST" }]
    }),
    editUser: builder.mutation<User, Partial<User> & { notValidate?: boolean }>(
      {
        query({ id, notValidate, ...patch }) {
          return {
            url: `users/${id}`,
            method: "PATCH",
            body: { ...patch }
          };
        },
        invalidatesTags: (_, __, arg) => {
          if (arg?.notValidate) return [];
          return [
            { type: "Users", id: "PARTIAL-LIST" },
            { type: "ContactDetail", id: arg.id }
          ];
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const rest = await queryFulfilled;
            const user = rest.data;
            const currentUser = store.getState().authentication.user as User;

            if (currentUser.id === arg.id) {
              dispatch(new UpdateCurrentUser({ ...currentUser, ...user }));

              /*   if (
                arg?.defaultLanguage &&
                arg?.defaultLanguage !== i18next.language
              ) {
                i18next.changeLanguage(arg.defaultLanguage as Languages);
              } */
            }

            const getContext = store
              .getState()
              .router.location.pathname.split("/")[2] as UserUpdateContext;
            if (getContext === UserUpdateContext.CONTACT) {
              dispatch(
                extendedUserApiSlice.util.invalidateTags([
                  { type: "ContactDetail", id: arg?.id }
                ])
              );
            }
            dispatch(new AppAddSnackbar(i18n.t("users:updateUser"), "success"));
          } catch (error) {
            console.log("error");
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:update-failed")
              )
            );
          }
        }
      }
    ),
    changePassword: builder.mutation<
      string,
      Partial<User> & { current?: string }
    >({
      query(data) {
        const { id, password, current } = data;
        return {
          url: `/users/${id}/password`,
          method: "PATCH",
          body: { password, ...(current && { current }) }
        };
      },
      invalidatesTags: () => [{ type: "Users", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data === UserChangePasswordReturnMessage.WRONG_PASSWORD) {
            dispatch(
              new AppAddSnackbar(
                i18n.t("users:wrongCurrentPassword"),
                "error",
                {
                  autoHideDuration: 10000
                }
              )
            );
          } else {
            dispatch(
              new AppAddSnackbar(i18n.t("users:updatePassword"), "success")
            );
          }
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    archive: builder.mutation<void, Partial<User>>({
      query({ id }) {
        return {
          url: `/users/${id}/archived`,
          method: "DELETE"
        };
      },
      invalidatesTags: () => [{ type: "Users", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:users.archived-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:users.archived-failed")
            )
          );
        }
      }
    }),
    createContact: builder.mutation<
      User,
      Partial<User> & { context?: ContactContext }
    >({
      query({ context, ...body }) {
        return {
          url: `/users/contacts`,
          method: "POST",
          body: { ...body }
        };
      },
      invalidatesTags: (_, __, arg) => {
        if (!arg?.context) return [];
        return getInvalidatTagsContacts(arg.context) as any;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:create-success"), "success")
          );
        } catch (error) {
          const message = (error as any)?.error?.data?.message[0];
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed", {
                reason: `: ${message}`
              })
            )
          );
        }
      }
    }),
    updateContact: builder.mutation<
      User,
      Partial<User> & { context?: ContactContext }
    >({
      query({ id, context, ...patch }) {
        return {
          url: `/users/${id}/contacts`,
          method: "PATCH",
          body: { ...patch }
        };
      },
      invalidatesTags: (_, __, arg) => {
        if (!arg?.context) return [];
        return getInvalidatTagsContacts(arg.context) as any;
      },
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    inviteNewUser: builder.mutation<User, Partial<User>>({
      query(body) {
        return {
          url: `/users/invite`,
          method: "POST",
          body
        };
      },
      invalidatesTags: () => [{ type: "Users", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("users:fetchInviteSuccess"), "success")
          );
        } catch (error: any) {
          //console.log(error?.error?.data?.message);
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("users:createUserFailed")
            )
          );
        }
      }
    }),

    getUserSubmissions: builder.query<
      GetSubmissionsResponse,
      { id: string; query: string }
    >({
      query: ({ id, query }) => `/users/${id}/submissions${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "MySubmissions" as const,
                id
              })),
              { type: "MySubmissions", id: "PARTIAL-LIST" }
            ]
          : [{ type: "MySubmissions", id: "PARTIAL-LIST" }]
    }),
    sendInvitation: builder.mutation<string, Partial<User>>({
      query({ email, defaultLanguage }) {
        return {
          url: `/users/invitation`,
          method: "POST",
          body: { email, ...(defaultLanguage && { defaultLanguage }) }
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        try {
          if (data === "Sending Email!") {
            dispatch(
              new AppAddSnackbar(
                i18n.t("authentication:forgotPasswordSuccess"),
                "success",
                { autoHideDuration: 3000 }
              )
            );
          } else {
            dispatch(
              new AppAddSnackbar(
                data === "Already sent email"
                  ? i18n.t("authentication:alreadySent")
                  : i18n.t("authentication:mailNotFound"),
                "error",
                { autoHideDuration: 3000 }
              )
            );
          }
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error,
              i18n.t("authentication:fetchInviteFailed")
            )
          );
        }
      }
    }),
    mySubmissionUpdateStatus: builder.mutation<Submission, Partial<Submission>>(
      {
        query: ({ id, statusId }) => ({
          url: `submissions/${id}`,
          method: "PATCH",
          body: { statusId }
        }),
        invalidatesTags: () => [
          { type: "ProjectSubmissions", id: "PARTIAL-LIST" },
          { type: "MySubmissions", id: "PARTIAL-LIST" },
          { type: "Submissions", id: "PARTIAL-LIST" }
        ]
      }
    ),
    uploadUserAvatar: builder.mutation<
      File,
      {
        userId?: string;
        formData: FormData;
      }
    >({
      query: ({ formData, userId }) => ({
        url: `/users/avatar`,
        method: "POST",
        body: formData,
        formData: true
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const currentUser = store.getState().authentication.user as User;
          if (currentUser.id === arg.userId) {
            dispatch(
              new UpdateCurrentUser({
                ...currentUser,
                avatar: data
              })
            );
          }
          dispatch(new AppAddSnackbar(i18n.t("users:updateUser"), "success"));
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    deleteUserAvatar: builder.mutation<
      File,
      {
        fileId: string;
        userId?: string;
      }
    >({
      query: ({ fileId, userId }) => ({
        url: `/users/avatar/${fileId}/remove`,
        method: "DELETE"
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const currentUser = store.getState().authentication.user as User;
          if (currentUser.id === arg.userId) {
            dispatch(
              new UpdateCurrentUser({
                ...currentUser,
                avatar: null
              })
            );
          }
          dispatch(new AppAddSnackbar(i18n.t("users:updateUser"), "success"));
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    })
  }),
  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetBriefByUserIdQuery,
  useGetInternalsQuery,
  useGetContactsPaginatedQuery,
  useGetMyContactsPaginatedQuery,
  useGetAllUsersQuery,
  useGetAllUserOptionsQuery,
  useGetAllUserWExternalSalesManagementsOptionsQuery,
  useInviteNewUserMutation,
  useArchiveMutation,
  useChangePasswordMutation,
  useEditUserMutation,
  useGetUsersQuery,
  usePrefetch: usePrefetchWUser,
  useCreateContactMutation,
  useUpdateContactMutation,
  useGetUserSubmissionsQuery,
  useMySubmissionUpdateStatusMutation,
  useSendInvitationMutation,
  useUploadUserAvatarMutation,
  useDeleteUserAvatarMutation,
  useGetCompanyContactPaginatedQuery,
  useGetOneContactQuery,
  useGetContactsQuery
} = extendedUserApiSlice;

export const getAllUserOptions = createSelector(
  (state: RootStateQuery) =>
    state.api.queries["getAllUserOptions(undefined)"]
      ?.data as AutoCompleteOption[],
  (users) => users || undefined
);

export const getAllUserOptionsTasks = createSelector(
  (state: RootStateQuery) =>
    state.api.queries["getAllUserWExternalSalesManagementsOptions(undefined)"]
      ?.data as AutoCompleteOption[],
  (users) => users || undefined
);
