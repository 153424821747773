import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Theme,
  createStyles,
  makeStyles,
  ListItemIcon,
  MenuList,
  MenuItem
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { MyAvatar } from "../../../../components/common/User/MyAvatar";
import { getLanguage } from "../../../../reducers/app/selector";
import { getDistanceBetweenDatesInWordsUtc } from "../../../../utils/function/getDistanceBetweenDate";
import { getUserDisplayName } from "../../../../utils/function/transformEntityToOptions";
import { User } from "../../../../entities/user";
import {
  Notification,
  NottificationSettingsCategoriesEnum
} from "../../../../services/notifications/notifications.type";
import { NotificationCategoryDisplay } from "./NotificationCategoryDisplay";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { MyButton } from "../../../../components/common/MyButton";
import { MyTypography } from "../../../../components/common/MyTypography";
import { useTranslation } from "react-i18next";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ArchiveIcon from "@material-ui/icons/Archive";
import { ReactComponent as MarkUnreadIcon } from "../assets/unread.svg";
import { useUpdateNotificationMutation } from "../../../../services/notifications/notifications.service";

interface NotificationHeaderProps {
  user: User;
  date: Date;
  children: JSX.Element;
  notification: Notification;
  isHovered?: boolean;
  archivingView?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    multiline: {
      marginTop: 0
    },
    content: {
      color: "#667085",
      "&>span": {
        "&>b": {
          color: theme.palette.primary.main
        }
      },
      "&>div >span": {
        "&>b": {
          color: theme.palette.primary.main
        }
      }
    },
    container: {
      width: "100%",
      display: "flex",
      position: "relative",
      alignItems: "center",
      paddingTop: "8px",
      paddingBottom: "8px",
      justifyContent: "flex-start"
    },
    userName: {
      fontWeight: 600,
      textTransform: "capitalize"
    },
    dotDate: {
      marginLeft: "5px",
      marginRight: "5px",
      color: "#667085",
      fontSize: ".8rem"
    },
    readAction: {
      color: "#2E5BFF",
      position: "absolute",
      right: "5px",
      top: "0",
      "& > svg": {
        color: "#2E5BFF",
        cursor: "pointer",
        position: "absolute",
        top: "0"
      }
    },
    date: { color: "#667085", fontSize: ".8rem" }
  })
);

export const NotificationHeader: React.FC<NotificationHeaderProps> = ({
  user,
  date,
  children,
  notification,
  isHovered = false,
  archivingView = false
}) => {
  const classes = useStyles();
  const displayName = useMemo(() => getUserDisplayName(user), [user]);
  const lang = useSelector(getLanguage);
  const { t } = useTranslation("backoffice");

  const [updateNotification] = useUpdateNotificationMutation();

  const isAvatar = [
    NottificationSettingsCategoriesEnum.PROJECT_CREATION_COMMENT,
    NottificationSettingsCategoriesEnum.TASK_CREATION_COMMENT,
    NottificationSettingsCategoriesEnum.PROJECT_CREATION_FILE,
    NottificationSettingsCategoriesEnum.COMMENT_MENTION,
    NottificationSettingsCategoriesEnum.PROJECT_UPDATE_STATUS,
    NottificationSettingsCategoriesEnum.TASK_UPDATE_STATUS,
    NottificationSettingsCategoriesEnum.PROJECT_COMMENT_MENTION,
    NottificationSettingsCategoriesEnum.TASK_COMMENT_MENTION
  ].includes(
    notification?.events?.[0]?.category as NottificationSettingsCategoriesEnum
  );

  return (
    <Box style={{ padding: "5px" }}>
      <ListItem
        disableGutters
        style={{ alignItems: isAvatar ? "flex-start" : "center" }}
        component={"div"}
      >
        {isAvatar ? (
          <ListItemAvatar style={{ minWidth: "46px" }}>
            <MyAvatar
              url={user?.avatar?.url}
              innerText={displayName.initials}
              style={{ margin: 0 }}
              avatarStyle={{
                backgroundColor: indigo[100]
              }}
            ></MyAvatar>
          </ListItemAvatar>
        ) : (
          <ListItemIcon style={{ minWidth: "46px" }}>
            <NotificationCategoryDisplay notification={notification} isIcon />
          </ListItemIcon>
        )}
        <ListItemText
          classes={{
            secondary: classes.content
          }}
          primary={
            <Box style={{ position: "relative" }}>
              <Box display="flex" alignItems={"center"}>
                {isAvatar ? (
                  <Typography className={classes.userName}>
                    {displayName.name}
                  </Typography>
                ) : (
                  <NotificationCategoryDisplay
                    notification={notification}
                    isHeader
                  />
                )}
                <Typography className={classes.dotDate}>•</Typography>
                <Typography className={classes.date}>
                  {getDistanceBetweenDatesInWordsUtc(date, lang)}
                </Typography>
                <Typography className={classes.dotDate}>•</Typography>
              </Box>

              <Box
                onClick={(e) => {
                  if (e.defaultPrevented) return;
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {isHovered && !archivingView && (
                  <MyButton
                    disableRipple
                    disableFocusRipple
                    className={classes.readAction}
                    menuProps={{
                      style: { padding: 0, margin: 0 },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      }
                    }}
                    menus={({ onClose }) => (
                      <MenuList
                        style={{ width: 300, outline: "none" }}
                        disablePadding
                      >
                        <MenuItem
                          onClick={async () => {
                            updateNotification({
                              id: notification.id,
                              isRead: !notification.isRead,
                              isArchived: notification.isArchived,
                              category: notification.category
                            });
                            onClose();
                          }}
                        >
                          <MyTypography
                            variant="body2"
                            leftIcon={
                              notification.isRead ? (
                                <MarkUnreadIcon />
                              ) : (
                                <DoneAllIcon />
                              )
                            }
                          >
                            {notification.isRead
                              ? t("notifications.inApp.actions.markAsUnread")
                              : t("notifications.inApp.actions.markAsRead")}
                          </MyTypography>
                        </MenuItem>
                        <MenuItem
                          onClick={async () => {
                            updateNotification({
                              id: notification.id,
                              isArchived: true,
                              isRead: notification.isRead,
                              category: notification.category
                            });
                            onClose();
                          }}
                        >
                          <MyTypography
                            variant="body2"
                            leftIcon={<ArchiveIcon />}
                          >
                            {t("notifications.inApp.actions.archive")}
                          </MyTypography>
                        </MenuItem>
                      </MenuList>
                    )}
                  >
                    <MyTypography
                      variant="body2"
                      color="primary"
                      data-id="action-notification"
                      rightIcon={
                        <MoreHorizIcon
                          color="primary"
                          style={{ fontSize: "14px" }}
                        />
                      }
                    />
                  </MyButton>
                )}
                {!notification.isRead && !isHovered && (
                  <MyButton
                    className={classes.readAction}
                    disableRipple
                    disableFocusRipple
                  >
                    <FiberManualRecordIcon
                      style={{ fontSize: "14px" }}
                      color="primary"
                    />
                  </MyButton>
                )}
              </Box>
            </Box>
          }
          secondary={<>{children}</>}
        />
      </ListItem>
    </Box>
  );
};
