import React, { Suspense, useEffect } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import RootApplication from "./pages";
import { runSaga, store, persistor } from "./reducers/store";
import * as serviceWorker from "./serviceWorker";
import "./utils/i18n";
import { UpdateHeader } from "./components/common/UpdateHeader";
import { saveToken } from "./utils/reconnection";

export const WebApp: React.FunctionComponent = () => {
  useEffect(() => {
    if (window.location.pathname === "/sign-in-by-token") {
      const token = window.location.search.split("?")[1].split("=")[1];
      saveToken(token as string);
      window.close();
    }
  }, []);

  return (
    <Suspense fallback={<></>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <UpdateHeader favIcon={""} title={""} />
          <RootApplication />
        </PersistGate>
      </Provider>
    </Suspense>
  );
};

runSaga();
render(<WebApp />, document.getElementById("root"));

serviceWorker.unregister();
