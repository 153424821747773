import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { AppSetting } from "../../reducers/appSettings/entity";

interface InitMetaEntityCustomFieldsProps {
  config: AppSetting["sentry"];
}

export const InitSentry: React.FC<InitMetaEntityCustomFieldsProps> = ({
  config
}) => {
  useEffect(() => {
    if (config) {
      Sentry.init({
        dsn: config.dsn,
        environment: config.environment,
        integrations: [
          Sentry.replayIntegration({ maskAllText: false, blockAllMedia: true })
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: "hipe-frontend@" + process.env.REACT_APP_LAST_COMMIT
      });
    }
  }, [config]);

  return null;
};
