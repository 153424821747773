import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import HttpBackendMultiLoad from "i18next-multiload-backend-adapter";
import LocalStorageBackend from "i18next-localstorage-backend";
import { baseURL } from "../request";
import { Languages } from "../function/jsonTranslator";

const fallbackLang = localStorage.getItem("defaultLanguage");
export const I18N_CACHE_PREFIX = "i18next_res_";

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    cache: {
      enabled: true
    },
    backend: {
      backends: [
        LocalStorageBackend,
        new HttpBackendMultiLoad(null, {
          backend: HttpBackend,
          backendOption: {
            loadPath: `${baseURL}/translations/locales?lng={{lng}}&ns={{ns}}`
          }
        })
      ],
      backendOptions: [
        {
          prefix: I18N_CACHE_PREFIX,
          expirationTime: 24 * 60 * 60 * 1000 // 24 hours
        },
        {}
      ]
    },
    ns: [
      "yup",
      "users",
      "home",
      "configurator",
      "routes",
      "reconnection",
      "authentication",
      "products",
      "briefs",
      "predictionRequests",
      "components",
      "briefDetail",
      "backoffice",
      "app",
      "saga",
      "common",
      "onboarding",
      "forgotPassword",
      "changePassword",
      "form",
      "lang"
    ],
    fallbackLng(_code) {
      return fallbackLang || Languages.EN;
    },
    debug: false, //process.env.NODE_ENV === "development",
    react: {
      useSuspense: true
    },
    keySeparator: false,

    // fallback on the default language and then return a translation instead of an empty string
    returnEmptyString: false
  });

export default i18n;
