import { SideName } from "../../entities/brief";

export const EXTERNAL_INPUT_PREFIX = "external.";

export enum ConfiguratorInputNames {
  PRODUCT_CATEGORY_ID = "productCategoryId",
  PRODUCT_ID = "productId",
  PRODUCT_SUBCATEGORY_IDS = "subCategoriesIds",
  LENGTH_DIMENSION = "lengthDimension",
  WIDTH_DIMENSION = "widthDimension",
  HEIGHT_DIMENSION = "heightDimension",
  TOTAL_RULE_LENGTH = "totalRuleLength",
  DISTANCE_BETWEEN_LAYOUT_HEIGHT = "distanceBetweenLayoutHeight",
  DISTANCE_BETWEEN_LAYOUT_WIDTH = "distanceBetweenLayoutWidth",
  WIDTH_GLUED_FLAP = "widthGluedFlap",
  WIDTH_INSIDE_FLAP = "widthInsideFlap",
  SPECIFY_REQUEST = "specifyRequest",
  BAG_PIECES = "bagPieces",
  ADD_WINDOW = "addWindow",
  WINDOW_WIDTH = "windowWidth",
  WINDOW_HEIGHT = "windowHeight",
  WINDOW_WANT_PROTECTION = "windowWantProtection",
  WINDOW_ID = "windowId",
  SPECIFY_WINDOW = "specifyWindow",
  MATTER_ID = "matterId",
  MATERIAL_TYPE_ID = "materialTypeId",
  GRAMMAGE = "grammage",
  MATERIAL_REFERENCE_ID = "materialReferenceId",
  MATERIAL_REFERENCE_NAME = "materialReferenceName",
  CARDBOARD_VERSO_POSITION = "cardboardVersoPosition",
  WANT_DECORATION = "wantDecoration",
  REFERENCES_COUNT = "referencesCount",
  WANT_OFFSET_PRINTING = "wantOffsetPrinting",
  SIDE_PRINTED = "sidePrinted",
  VERSO_COLOR_TYPE_CMYK = "versoColorTypeCMYK",
  VERSO_DIRECT_TONE_COLOR_TYPE = "versoDirectToneColorType",
  VERSO_DIRECT_TONE_COLOR_COUNT = "versoDirectToneColorCount",
  VERSO_COLOR_COUNT = "versoColorCount",
  VERSO_COLOR_QUALITY = "versoColorQuality", //legacy
  VERSO_INK_PERCENTAGE = "versoInkPercentage", //legacy
  RECTO_COLOR_TYPE_CMYK = "rectoColorTypeCMYK",
  RECTO_DIRECT_TONE_COLOR_TYPE = "rectoDirectToneColorType",
  RECTO_DIRECT_TONE_COLOR_COUNT = "rectoDirectToneColorCount",
  RECTO_COLOR_COUNT = "rectoColorCount",
  RECTO_COLOR_QUALITY = "rectoColorQuality", //legacy
  RECTO_INK_PERCENTAGE = "rectoInkPercentage", //legacy
  ADDITIONAL_INK_SCREENPRINTING = "additionalInkScreenPrinting", //legacy
  ADDITIONAL_INK_SCREENPRINTING_COLOR_COUNT = "additionalInkScreenPrintingColorCount", //legacy
  ADDITIONAL_INK_SCREENPRINTING_PERCENTAGE = "additionalInkScreenPrintingPercentage", //legacy
  NEED_STAMPING = "needStamping", //legacy
  STAMPING_METHOD = "stampingMethod", //legacy
  POLYESTER_LAMINATION_ID = "polyesterLaminationId", //legacy
  HOTSTAMPINGS = "hotstampings", //legacy

  // Finishes
  WANT_PROTECTION = "wantProtection",
  SIDE_PROTECTED = "sideProtected",
  WANT_VARNISH_RECTO = "wantVarnishRecto",
  WANT_VARNISH_VERSO = "wantVarnishVerso",
  VARNISH_METHOD_RECTO = "varnishMethodRecto",
  VARNISH_METHOD_VERSO = "varnishMethodVerso",
  FLEXO_VARNISH_RECTO_ID = "flexoVarnishRectoId",
  FLEXO_VARNISH_VERSO_ID = "flexoVarnishVersoId",
  FLEXO_VARNISH_COVERAGE_RECTO = "flexoVarnishCoverageRecto",
  FLEXO_VARNISH_COVERAGE_VERSO = "flexoVarnishCoverageVerso",
  FINISH_PARTIAL_SURFACE_RECTO = "finishPartialSurfaceRecto",
  FINISH_PARTIAL_SURFACE_VERSO = "finishPartialSurfaceVerso",
  FLEXO_PLATE_VARNISH_EXIST_RECTO = "flexoPlateVarnishExistRecto",
  FLEXO_PLATE_VARNISH_EXIST_VERSO = "flexoPlateVarnishExistVerso",
  FLEXO_PLATE_VARNISH_FORCE_RECTO = "flexoPlateVarnishForceRecto",
  FLEXO_PLATE_VARNISH_FORCE_VERSO = "flexoPlateVarnishForceVerso",
  FLEXO_PLATE_VARNISH_LAYOUT_COUNT_RECTO = "flexoPlateVarnishLayoutCountRecto",
  FLEXO_PLATE_VARNISH_LAYOUT_COUNT_VERSO = "flexoPlateVarnishLayoutCountVerso",
  VARNISH_PARAMS_OFFSET_RECTO = "varnishParamsOffsetRecto",
  VARNISH_PARAMS_OFFSET_VERSO = "varnishParamsOffsetVerso",
  WANT_LAMINATION_RECTO = "wantLaminationRecto",
  WANT_LAMINATION_VERSO = "wantLaminationVerso",
  LAMINATION_ID_RECTO = "laminationIdRecto",
  LAMINATION_ID_VERSO = "laminationIdVerso",
  WANT_FINISHES = "wantFinishes",
  SIDE_FINISHED = "sideFinished",
  WANT_LUXURY_VARNISH_RECTO = "wantLuxuryVarnishRecto",
  WANT_LUXURY_VARNISH_VERSO = "wantLuxuryVarnishVerso",
  LUXURY_VARNISH_PARAMS_RECTO = "luxuryVarnishParamsRecto",
  LUXURY_VARNISH_PARAMS_VERSO = "luxuryVarnishParamsVerso",
  WANT_EMBOSSING_DEBOSSING_RECTO = "wantEmbossingDebossingRecto",
  WANT_EMBOSSING_DEBOSSING_VERSO = "wantEmbossingDebossingVerso",
  EMBOSSING_DEBOSSING_PARAMS_RECTO = "embossingDebossingParamsRecto",
  EMBOSSING_DEBOSSING_PARAMS_VERSO = "embossingDebossingParamsVerso",
  // end Finishes

  PROTECTION_IDS = "protectionIds", //legacy
  WANT_LUXURY = "wantLuxury", //legacy
  LAMINATION_ID = "laminationId", //legacy
  BASIC_VARNISH_ID = "basicVarnishId", //legacy
  LUXURY_VARNISH_ID = "luxuryVarnishId", //legacy
  WANT_EMBOSSING_DEBOSSING = "wantEmbossingDebossing", //legacy
  EMBOSSING_DEBOSSINGS = "embossingDebossings", //legacy
  DECORATIONS_COUNT = "decorationsCount",
  INTERCOM = "intercom",
  DELIVERY_ZIP_CODE = "deliveryZipCode",
  COMMENT = "comment",
  WANT_PRINT_ON_CREASING_RECTO = "wantPrintOnCreasingRecto",
  WANT_TECHNICAL_PRINTING_RECTO = "wantTechnicalPrintingRecto",
  WANT_PRINT_ON_CREASING_VERSO = "wantPrintOnCreasingVerso",
  WANT_TECHNICAL_PRINTING_VERSO = "wantTechnicalPrintingVerso",
  WANT_FULL_SURFACE_VERSO = "wantFullSurfaceVerso",
  WANT_PRINT_ON_EDGE_RECTO = "wantPrintOnEdgeRecto",
  WANT_FULL_SURFACE_RECTO = "wantFullSurfaceRecto",
  WANT_DECOR_AMALGAM = "wantDecorAmalgam",
  PRINT_METHOD_RECTO = "printMethodRecto",
  DIRECT_TONE_PARAMS_RECTO = "directToneParamsRecto",
  DIRECT_TONE_PARAMS_VERSO = "directToneParamsVerso",
  SIDE_VARIATION_PRINT = "sideVariationPrint",
  PRINT_METHOD_VERSO = "printMethodVerso",
  WANT_PRINT_ON_EDGE_VERSO = "wantPrintOnEdgeVerso",
  CMYK_PARAMS_RECTO = "cmykParamsRecto",
  CMYK_PARAMS_VERSO = "cmykParamsVerso",
  ADD_MECANISATION = "addMecanisation",
  ADD_TEAR_STRIP = "addTearStrip",
  MATERIAL_FLUTE_ID = "materialFluteId",
  MATERIAL_QUALITY = "materialQuality",
  MATERIAL_LINER_RECTO_ID = "materialLinerRectoId",
  MATERIAL_LINER_VERSO_ID = "materialLinerVersoId",
  ADD_SEALING_STRIP = "addSealingStrip",
  SEALING_STRIP_LENGTH = "sealingStripLength",
  CORRUGATED_MATERIAL_ID = "corrugatedMaterialId",
  CORRUGATED_MATERIAL_COMPOSITION_IDS = "corrugatedMaterialCompositionIds",
  DIVIDERS_LENGTH_BIG_ELEMENT = "dividersLengthBigElement",
  DIVIDERS_LENGTH_SMALL_ELEMENT = "dividersLengthSmallElement",
  DIVIDERS_HEIGHT_ELEMENT = "dividersHeightElement",
  DIVIDERS_NOTCH_BORDER_DISTANCE = "dividersNotchBorderDistance",
  DIVIDERS_SPACE_BETWEEN_NOTCHES_BIG_ELEMENT = "dividersSpaceBetweenNotchesBigElement",
  DIVIDERS_SPACE_BETWEEN_NOTCHES_SMALL_ELEMENT = "dividersSpaceBetweenNotchesSmallElement",
  DIECUT_TOOL_EXIST = "dieCutToolExist",
  DIECUT_TOOL_LAYOUT_COUNT = "dieCutToolLayoutCount",
  DIECUT_TOOL_FORCE = "dieCutToolForce",
  DIECUT_TOOL_MACHINE_IDS = "dieCutToolMachineIds",
  FLEXO_PLATE_INK_EXIST_VERSO = "flexoPlateInkExistVerso",
  FLEXO_PLATE_INK_FORCE_VERSO = "flexoPlateInkForceVerso",
  FLEXO_PLATE_INK_LAYOUT_COUNT_VERSO = "flexoPlateInkLayoutCountVerso",
  FLEXO_PLATE_INK_EXIST_RECTO = "flexoPlateInkExistRecto",
  FLEXO_PLATE_INK_FORCE_RECTO = "flexoPlateInkForceRecto",
  FLEXO_PLATE_INK_LAYOUT_COUNT_RECTO = "flexoPlateInkLayoutCountRecto",
  DIGITAL_PRINTING_MODE_RECTO_ID = "digitalPrintingModeRectoId",
  DIGITAL_PRINTING_MODE_VERSO_ID = "digitalPrintingModeVersoId",
  DIGITAL_PRINTING_TYPE_RECTO_ID = "digitalPrintingTypeRectoId",
  DIGITAL_PRINTING_TYPE_VERSO_ID = "digitalPrintingTypeVersoId",
  CMYKW_PARAMS_DIGITAL_RECTO = "cmykwParamsDigitalRecto",
  CMYKW_PARAMS_DIGITAL_VERSO = "cmykwParamsDigitalVerso",
  VERSO_COLOR_TYPE_CMYK_OFFSET = "versoColorTypeCMYKOffset",
  VERSO_DIRECT_TONE_COLOR_TYPE_OFFSET = "versoDirectToneColorTypeOffset",
  RECTO_COLOR_TYPE_CMYK_OFFSET = "rectoColorTypeCMYKOffset",
  RECTO_DIRECT_TONE_COLOR_TYPE_OFFSET = "rectoDirectToneColorTypeOffset",
  DIRECT_TONE_PARAMS_OFFSET_RECTO = "directToneParamsOffsetRecto",
  DIRECT_TONE_PARAMS_OFFSET_VERSO = "directToneParamsOffsetVerso",
  CMYK_PARAMS_OFFSET_RECTO = "cmykParamsOffsetRecto",
  CMYK_PARAMS_OFFSET_VERSO = "cmykParamsOffsetVerso",
  SCREENPRINTING_RECTO = "screenPrintingRecto",
  SCREENPRINTING_VERSO = "screenPrintingVerso",
  DIRECT_TONE_PARAMS_SCREENPRINTING_RECTO = "directToneParamsScreenPrintingRecto",
  DIRECT_TONE_PARAMS_SCREENPRINTING_VERSO = "directToneParamsScreenPrintingVerso",
  WANT_STAMPING_RECTO = "wantStampingRecto",
  WANT_STAMPING_VERSO = "wantStampingVerso",
  STAMPING_METHOD_RECTO = "stampingMethodRecto",
  STAMPING_METHOD_VERSO = "stampingMethodVerso",
  HOTSTAMPING_PARAMS_RECTO = "hotstampingParamsRecto",
  HOTSTAMPING_PARAMS_VERSO = "hotstampingParamsVerso",
  POLYESTER_LAMINATION_ID_RECTO = "polyesterLaminationIdRecto",
  POLYESTER_LAMINATION_ID_VERSO = "polyesterLaminationIdVerso",
  LITHO_LAMINATION_MATERIAL_REFERENCE_RECTO_ID = "lithoLaminationMaterialReferenceRectoId",
  LITHO_LAMINATION_MATERIAL_REFERENCE_VERSO_ID = "lithoLaminationMaterialReferenceVersoId",
  LITHO_LAMINATION_EXTRA_BORDER_RECTO = "lithoLaminationExtraBorderRecto",
  LITHO_LAMINATION_EXTRA_BORDER_VERSO = "lithoLaminationExtraBorderVerso"
}

export const DimensionInputsLabel: Record<string, string> = {
  [ConfiguratorInputNames.LENGTH_DIMENSION]: "packagingType.form.length",
  [ConfiguratorInputNames.WIDTH_DIMENSION]: "packagingType.form.width",
  [ConfiguratorInputNames.HEIGHT_DIMENSION]: "packagingType.form.height",
  [ConfiguratorInputNames.TOTAL_RULE_LENGTH]:
    "packagingType.form.totalRuleLength",
  [ConfiguratorInputNames.DISTANCE_BETWEEN_LAYOUT_HEIGHT]:
    "packagingType.form.distanceBetweenLayoutHeight",
  [ConfiguratorInputNames.DISTANCE_BETWEEN_LAYOUT_WIDTH]:
    "packagingType.form.distanceBetweenLayoutWidth",
  [ConfiguratorInputNames.WIDTH_GLUED_FLAP]: "packagingType.form.glueFlap",
  [ConfiguratorInputNames.WIDTH_INSIDE_FLAP]: "packagingType.form.insideFlap",
  [ConfiguratorInputNames.DIVIDERS_LENGTH_BIG_ELEMENT]:
    "packagingType.form.dividersLengthBigElementLabel",
  [ConfiguratorInputNames.DIVIDERS_LENGTH_SMALL_ELEMENT]:
    "packagingType.form.dividersLengthSmallElementLabel",
  [ConfiguratorInputNames.DIVIDERS_HEIGHT_ELEMENT]:
    "packagingType.form.dividersHeightElementLabel",
  [ConfiguratorInputNames.DIVIDERS_NOTCH_BORDER_DISTANCE]:
    "packagingType.form.dividersNotchBorderDistanceLabel",
  [ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_BIG_ELEMENT]:
    "packagingType.form.dividersSpaceBetweenNotchesBigElementLabel",
  [ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_SMALL_ELEMENT]:
    "packagingType.form.dividersSpaceBetweenNotchesSmallElementLabel"
};

export const ConfiguratorInputMandatory = [
  ConfiguratorInputNames.DECORATIONS_COUNT,
  ConfiguratorInputNames.PRODUCT_ID
];

export enum FieldType {
  TEXT = "text",
  TEXT_AREA = "textArea",
  NUMBER = "number",
  PERCENTAGE = "percentage",
  CHECKBOX = "checkbox",
  SWITCH = "switch",
  RADIO = "radio",
  SELECT = "select",
  MULTI_SELECT = "multiSelect",
  MIXED = "mixed",
  COMPUTED = "computed"
}

export const FieldTypeTranslateKey: Record<FieldType, string> = {
  [FieldType.TEXT]: "settings.configurator-inputs.fieldType.text",
  [FieldType.TEXT_AREA]: "settings.configurator-inputs.fieldType.textArea",
  [FieldType.NUMBER]: "settings.configurator-inputs.fieldType.number",
  [FieldType.PERCENTAGE]: "settings.configurator-inputs.fieldType.percentage",
  [FieldType.SWITCH]: "settings.configurator-inputs.fieldType.switch",
  [FieldType.CHECKBOX]: "settings.configurator-inputs.fieldType.checkbox",
  [FieldType.RADIO]: "settings.configurator-inputs.fieldType.radio",
  [FieldType.SELECT]: "settings.configurator-inputs.fieldType.select",
  [FieldType.MULTI_SELECT]:
    "settings.configurator-inputs.fieldType.multiSelect",
  [FieldType.MIXED]: "settings.configurator-inputs.fieldType.mixed",
  [FieldType.COMPUTED]: "settings.configurator-inputs.fieldType.computed"
};

export enum ConfiguratorInputEnum {
  SIDE_PROTECTED = ConfiguratorInputNames.SIDE_PROTECTED,
  VARNISH_METHOD_RECTO = ConfiguratorInputNames.VARNISH_METHOD_RECTO,
  VARNISH_METHOD_VERSO = ConfiguratorInputNames.VARNISH_METHOD_VERSO,
  SIDE_FINISHED = ConfiguratorInputNames.SIDE_FINISHED,
  SIDE_PRINTED = ConfiguratorInputNames.SIDE_PRINTED,
  INTERCOM = ConfiguratorInputNames.INTERCOM,
  PRINT_METHOD_RECTO = ConfiguratorInputNames.PRINT_METHOD_RECTO,
  SIDE_VARIATION_PRINT = ConfiguratorInputNames.SIDE_VARIATION_PRINT,
  PRINT_METHOD_VERSO = ConfiguratorInputNames.PRINT_METHOD_VERSO,
  STAMPING_METHOD_RECTO = ConfiguratorInputNames.STAMPING_METHOD_RECTO,
  STAMPING_METHOD_VERSO = ConfiguratorInputNames.STAMPING_METHOD_VERSO
}

export enum ConfiguratorInputFetch {
  DIGITAL_PRINTING_MODE_RECTO_ID = ConfiguratorInputNames.DIGITAL_PRINTING_MODE_RECTO_ID,
  DIGITAL_PRINTING_MODE_VERSO_ID = ConfiguratorInputNames.DIGITAL_PRINTING_MODE_VERSO_ID,
  DIGITAL_PRINTING_TYPE_RECTO_ID = ConfiguratorInputNames.DIGITAL_PRINTING_TYPE_RECTO_ID,
  DIGITAL_PRINTING_TYPE_VERSO_ID = ConfiguratorInputNames.DIGITAL_PRINTING_TYPE_VERSO_ID,
  LAMINATION_ID_RECTO = ConfiguratorInputNames.LAMINATION_ID_RECTO,
  LAMINATION_ID_VERSO = ConfiguratorInputNames.LAMINATION_ID_VERSO,
  FLEXO_VARNISH_RECTO_ID = ConfiguratorInputNames.FLEXO_VARNISH_RECTO_ID,
  FLEXO_VARNISH_VERSO_ID = ConfiguratorInputNames.FLEXO_VARNISH_VERSO_ID,
  CORRUGATED_MATERIAL_ID = ConfiguratorInputNames.CORRUGATED_MATERIAL_ID,
  CORRUGATED_MATERIAL_COMPOSITION_IDS = ConfiguratorInputNames.CORRUGATED_MATERIAL_COMPOSITION_IDS,
  MATERIAL_REFERENCE_ID = ConfiguratorInputNames.MATERIAL_REFERENCE_ID,
  POLYESTER_LAMINATION_ID_RECTO = ConfiguratorInputNames.POLYESTER_LAMINATION_ID_RECTO,
  POLYESTER_LAMINATION_ID_VERSO = ConfiguratorInputNames.POLYESTER_LAMINATION_ID_VERSO,
  WINDOW_ID = ConfiguratorInputNames.WINDOW_ID
}

export enum OptionsType {
  TEXT = "text",
  TEXT_AREA = "textArea",
  NUMBER = "number",
  PERCENTAGE = "percentage",
  BOOLEAN = "boolean",
  ENUM = "enum",
  ENTITY = "entity",
  OBJECT = "object",
  COMPONENT = "component",
  COMPUTED = "computed"
}

export const unmanagedRAQBOptionsType = [
  OptionsType.COMPONENT,
  OptionsType.OBJECT,
  OptionsType.COMPUTED
];

export enum SectionNames {
  PACKAGING_TYPE = "PACKAGING_TYPE",
  MATERIALS = "MATERIALS",
  DECORATIONS = "DECORATIONS",
  FINISHES = "FINISHES",
  PURCHASE_CONDITION = "PURCHASE_CONDITION"
}

export interface ConfiguratorInputMetaData {
  fieldType: FieldType;
  optionsType: OptionsType;
  section: SectionNames | undefined; //not used yet
  /** if true, the input should not be listed in the product formula */
  isExcludedFromFromula?: boolean;
  /** if true, requirement and visibility are not configurable */
  showOnlyAdvancedOptions?: boolean;
  /** default visibility value in corresponding input options (especially when showOnlyAdvancedOptions) */
  defaultVisibility?: boolean;
  /** if true, the input doesn't have to be undefined if precondition is not met or input option doesn't exist (always taken into account) */
  optional?: boolean;
}

type Side = "sideProtected" | "sideFinished";
const isSideOf = (kind: Side, side: "verso" | "recto") =>
  `(values.${kind} === "${
    side === "recto" ? SideName.OUTSIDE : SideName.INSIDE
  }" || values.${kind} === "${SideName.INSIDE_AND_OUTSIDE}")`;

const atLeastThisInputOr = (input: ConfiguratorInputNames) =>
  `values.${input} !== true`;

const FinishesInputMetaDataByName = {
  // WANT PROTECTION
  [ConfiguratorInputNames.WANT_PROTECTION]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.SIDE_PROTECTED]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_VARNISH_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_VARNISH_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.VARNISH_METHOD_RECTO]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.VARNISH_METHOD_VERSO]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FLEXO_VARNISH_RECTO_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FLEXO_VARNISH_VERSO_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FINISH_PARTIAL_SURFACE_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FINISH_PARTIAL_SURFACE_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FLEXO_VARNISH_COVERAGE_RECTO]: {
    fieldType: FieldType.PERCENTAGE,
    optionsType: OptionsType.PERCENTAGE,
    section: SectionNames.FINISHES,
    optional: true //because previously it was default(1)
  },
  [ConfiguratorInputNames.FLEXO_VARNISH_COVERAGE_VERSO]: {
    fieldType: FieldType.PERCENTAGE,
    optionsType: OptionsType.PERCENTAGE,
    section: SectionNames.FINISHES,
    optional: true //because previously it was default(1)
  },
  [ConfiguratorInputNames.FLEXO_PLATE_VARNISH_EXIST_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FLEXO_PLATE_VARNISH_EXIST_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FLEXO_PLATE_VARNISH_FORCE_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FLEXO_PLATE_VARNISH_FORCE_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FLEXO_PLATE_VARNISH_LAYOUT_COUNT_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.FLEXO_PLATE_VARNISH_LAYOUT_COUNT_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.VARNISH_PARAMS_OFFSET_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.VARNISH_PARAMS_OFFSET_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_LAMINATION_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_LAMINATION_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.LAMINATION_ID_RECTO]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.LAMINATION_ID_VERSO]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.FINISHES
  },
  // WANT FINISHES
  [ConfiguratorInputNames.WANT_FINISHES]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.SIDE_FINISHED]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_LUXURY_VARNISH_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_LUXURY_VARNISH_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.LUXURY_VARNISH_PARAMS_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.LUXURY_VARNISH_PARAMS_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_EMBOSSING_DEBOSSING_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_EMBOSSING_DEBOSSING_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.EMBOSSING_DEBOSSING_PARAMS_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.EMBOSSING_DEBOSSING_PARAMS_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  }
};

export const ConfiguratorInputMetaDataByName: Record<
  ConfiguratorInputNames,
  ConfiguratorInputMetaData
> = {
  [ConfiguratorInputNames.PRODUCT_CATEGORY_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.PACKAGING_TYPE,
    optional: true
  },
  [ConfiguratorInputNames.PRODUCT_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.PACKAGING_TYPE,
    optional: true
  },
  [ConfiguratorInputNames.PRODUCT_SUBCATEGORY_IDS]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.PACKAGING_TYPE,
    optional: true
  },
  [ConfiguratorInputNames.LENGTH_DIMENSION]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.WIDTH_DIMENSION]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.HEIGHT_DIMENSION]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.TOTAL_RULE_LENGTH]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DISTANCE_BETWEEN_LAYOUT_HEIGHT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DISTANCE_BETWEEN_LAYOUT_WIDTH]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.WIDTH_GLUED_FLAP]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.WIDTH_INSIDE_FLAP]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.SPECIFY_REQUEST]: {
    fieldType: FieldType.TEXT_AREA,
    optionsType: OptionsType.TEXT_AREA,
    section: SectionNames.PACKAGING_TYPE,
    optional: true
  },
  [ConfiguratorInputNames.BAG_PIECES]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE,
    isExcludedFromFromula: true
  },
  [ConfiguratorInputNames.DIECUT_TOOL_EXIST]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DIECUT_TOOL_FORCE]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DIECUT_TOOL_MACHINE_IDS]: {
    fieldType: FieldType.MULTI_SELECT,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DIECUT_TOOL_LAYOUT_COUNT]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.ADD_WINDOW]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.WINDOW_WIDTH]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.WINDOW_HEIGHT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.WINDOW_WANT_PROTECTION]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.WINDOW_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.SPECIFY_WINDOW]: {
    fieldType: FieldType.TEXT_AREA,
    optionsType: OptionsType.TEXT_AREA,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.MATTER_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.COMPONENT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.MATERIAL_TYPE_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.COMPONENT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.GRAMMAGE]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.COMPONENT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.MATERIAL_REFERENCE_NAME]: {
    fieldType: FieldType.TEXT,
    optionsType: OptionsType.COMPONENT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.CARDBOARD_VERSO_POSITION]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.MATERIAL_REFERENCE_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.WANT_DECORATION]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.REFERENCES_COUNT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.DECORATIONS,
    isExcludedFromFromula: true
  },
  [ConfiguratorInputNames.WANT_OFFSET_PRINTING]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.SIDE_PRINTED]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.VERSO_COLOR_TYPE_CMYK]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_TYPE]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_COUNT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.DECORATIONS,
    isExcludedFromFromula: true,
    optional: true
  },
  [ConfiguratorInputNames.VERSO_COLOR_COUNT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.DECORATIONS,
    isExcludedFromFromula: true,
    optional: true
  },
  [ConfiguratorInputNames.VERSO_COLOR_QUALITY]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.VERSO_INK_PERCENTAGE]: {
    fieldType: FieldType.PERCENTAGE,
    optionsType: OptionsType.PERCENTAGE,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.RECTO_COLOR_TYPE_CMYK]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS,
    optional: true
  },
  [ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_TYPE]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS,
    optional: true
  },
  [ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_COUNT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.DECORATIONS,
    isExcludedFromFromula: true,
    optional: true
  },
  [ConfiguratorInputNames.RECTO_COLOR_COUNT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.DECORATIONS,
    isExcludedFromFromula: true,
    optional: true
  },
  [ConfiguratorInputNames.RECTO_COLOR_QUALITY]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.RECTO_INK_PERCENTAGE]: {
    fieldType: FieldType.PERCENTAGE,
    optionsType: OptionsType.PERCENTAGE,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.ADDITIONAL_INK_SCREENPRINTING]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.ADDITIONAL_INK_SCREENPRINTING_COLOR_COUNT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.DECORATIONS,
    isExcludedFromFromula: true
  },
  [ConfiguratorInputNames.ADDITIONAL_INK_SCREENPRINTING_PERCENTAGE]: {
    fieldType: FieldType.PERCENTAGE,
    optionsType: OptionsType.PERCENTAGE,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.NEED_STAMPING]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.STAMPING_METHOD]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.POLYESTER_LAMINATION_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.HOTSTAMPINGS]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.PROTECTION_IDS]: {
    fieldType: FieldType.MULTI_SELECT,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_LUXURY]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.LAMINATION_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.BASIC_VARNISH_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.LUXURY_VARNISH_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.WANT_EMBOSSING_DEBOSSING]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.EMBOSSING_DEBOSSINGS]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.FINISHES
  },
  [ConfiguratorInputNames.DECORATIONS_COUNT]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.PURCHASE_CONDITION,
    showOnlyAdvancedOptions: true,
    defaultVisibility: true,
    optional: true
  },
  [ConfiguratorInputNames.INTERCOM]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: undefined,
    optional: true
  },
  [ConfiguratorInputNames.DELIVERY_ZIP_CODE]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: undefined,
    isExcludedFromFromula: true,
    optional: true
  },
  [ConfiguratorInputNames.COMMENT]: {
    fieldType: FieldType.TEXT_AREA,
    optionsType: OptionsType.TEXT_AREA,
    section: undefined,
    optional: true
  },
  [ConfiguratorInputNames.WANT_PRINT_ON_CREASING_RECTO]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_TECHNICAL_PRINTING_RECTO]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_PRINT_ON_CREASING_VERSO]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_TECHNICAL_PRINTING_VERSO]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_FULL_SURFACE_VERSO]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_PRINT_ON_EDGE_RECTO]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_FULL_SURFACE_RECTO]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_DECOR_AMALGAM]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.PRINT_METHOD_RECTO]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIRECT_TONE_PARAMS_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIRECT_TONE_PARAMS_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.SIDE_VARIATION_PRINT]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.PRINT_METHOD_VERSO]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_PRINT_ON_EDGE_VERSO]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.CMYK_PARAMS_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.CMYK_PARAMS_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.ADD_MECANISATION]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.ADD_TEAR_STRIP]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.MATERIAL_FLUTE_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.COMPONENT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.MATERIAL_QUALITY]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.COMPONENT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.MATERIAL_LINER_RECTO_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.COMPONENT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.MATERIAL_LINER_VERSO_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.COMPONENT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.ADD_SEALING_STRIP]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.SEALING_STRIP_LENGTH]: {
    fieldType: FieldType.COMPUTED,
    optionsType: OptionsType.COMPUTED,
    section: undefined,
    showOnlyAdvancedOptions: true,
    defaultVisibility: false
  },
  [ConfiguratorInputNames.CORRUGATED_MATERIAL_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.CORRUGATED_MATERIAL_COMPOSITION_IDS]: {
    fieldType: FieldType.MULTI_SELECT,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.MATERIALS
  },
  [ConfiguratorInputNames.DIVIDERS_LENGTH_BIG_ELEMENT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DIVIDERS_LENGTH_SMALL_ELEMENT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DIVIDERS_HEIGHT_ELEMENT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DIVIDERS_NOTCH_BORDER_DISTANCE]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_BIG_ELEMENT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_SMALL_ELEMENT]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.PACKAGING_TYPE
  },
  [ConfiguratorInputNames.FLEXO_PLATE_INK_EXIST_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.FLEXO_PLATE_INK_FORCE_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.FLEXO_PLATE_INK_LAYOUT_COUNT_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.FLEXO_PLATE_INK_EXIST_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.FLEXO_PLATE_INK_FORCE_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.FLEXO_PLATE_INK_LAYOUT_COUNT_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIGITAL_PRINTING_MODE_RECTO_ID]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIGITAL_PRINTING_MODE_VERSO_ID]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIGITAL_PRINTING_TYPE_RECTO_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIGITAL_PRINTING_TYPE_VERSO_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.CMYKW_PARAMS_DIGITAL_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.CMYKW_PARAMS_DIGITAL_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.VERSO_COLOR_TYPE_CMYK_OFFSET]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_TYPE_OFFSET]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.RECTO_COLOR_TYPE_CMYK_OFFSET]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_TYPE_OFFSET]: {
    fieldType: FieldType.CHECKBOX,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIRECT_TONE_PARAMS_OFFSET_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIRECT_TONE_PARAMS_OFFSET_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.CMYK_PARAMS_OFFSET_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.CMYK_PARAMS_OFFSET_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.SCREENPRINTING_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.SCREENPRINTING_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIRECT_TONE_PARAMS_SCREENPRINTING_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.DIRECT_TONE_PARAMS_SCREENPRINTING_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_STAMPING_RECTO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.STAMPING_METHOD_RECTO]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.HOTSTAMPING_PARAMS_RECTO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.POLYESTER_LAMINATION_ID_RECTO]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.WANT_STAMPING_VERSO]: {
    fieldType: FieldType.SWITCH,
    optionsType: OptionsType.BOOLEAN,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.STAMPING_METHOD_VERSO]: {
    fieldType: FieldType.RADIO,
    optionsType: OptionsType.ENUM,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.HOTSTAMPING_PARAMS_VERSO]: {
    fieldType: FieldType.MIXED,
    optionsType: OptionsType.OBJECT,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.POLYESTER_LAMINATION_ID_VERSO]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.LITHO_LAMINATION_MATERIAL_REFERENCE_RECTO_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.LITHO_LAMINATION_MATERIAL_REFERENCE_VERSO_ID]: {
    fieldType: FieldType.SELECT,
    optionsType: OptionsType.ENTITY,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.LITHO_LAMINATION_EXTRA_BORDER_RECTO]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.DECORATIONS
  },
  [ConfiguratorInputNames.LITHO_LAMINATION_EXTRA_BORDER_VERSO]: {
    fieldType: FieldType.NUMBER,
    optionsType: OptionsType.NUMBER,
    section: SectionNames.DECORATIONS
  },
  ...FinishesInputMetaDataByName
};
