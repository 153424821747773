import { get, memoize } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "..";
import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";
import { getEachUserStandardRoleStatus } from "../authentication/selector";
import { EachUserRole } from "../authentication/types";
import { BriefElementInterInputOptionsComputed } from "../briefElementConfigurator/reducer";
import { getInputOptionsComputed } from "../briefElementConfigurator/selector";
import { ConfiguratorInputNames } from "../configurator-inputs/constant";
import { getExternalInputName } from "../configurator-inputs/method";
import { ConfiguratorInputSelector } from "../configurator-inputs/selector";
import { OperationSelector } from "../operations/selector";
import { Product } from "../products/entity";
import { getTotalProduct, ProductSelector } from "../products/selector";
import { getOperationsOptions } from "./../operations/selector";
import { getProductOptions } from "./../products/selector";
import {
  ConfiguratorInputOption,
  InputOptionsAdvanced,
  JsonLogicFormula
} from "./entity";
import { getJsonLogicFormulaAlways } from "./method";
import { ConfiguratorInputOptionAdapter } from "./reducer";
import { RolesWithContextType } from "../../entities/role";

export interface ConfiguratorInputOptionComputed {
  /** Input name including external input name with its prefix */
  id: string;
  label?: DynamicJsonTranslator | null;
  helperText?: DynamicJsonTranslator | null;
  external: boolean;
  linked: boolean;
  visible: boolean;
  visibleExcludedRoles?: RolesWithContextType[];
  required: boolean;
  formula: string;
  jsonLogicFormula: JsonLogicFormula;
  options?: InputOptionsAdvanced | null;
  interInputId?: string;
  interInputFormula?: string;
  precondition?: boolean;
  /** Input name and external input name without its prefix */
  name: ConfiguratorInputNames;
}

export interface InterInputOptionComputed extends ConfiguratorInputOption {
  jsonLogicFormula: JsonLogicFormula;
}

export type ExternalInputName = string; //with EXTERNAL_INPUT_PREFIX
export type InputName = ConfiguratorInputNames | ExternalInputName;
export type InputUUID = string;

export type ConfiguratorInputOptionsResult = Record<
  InputName,
  ConfiguratorInputOptionComputed
>;

export interface ConfiguratorInputOptionComputedPartial
  extends ConfiguratorInputOption {
  precondition: boolean;
}

export type ConfiguratorInputOptionsComputed = Record<
  InputUUID,
  ConfiguratorInputOptionComputedPartial
>;

export interface ProductInputOptions {
  // Input options
  iO: Map<InputUUID, ConfiguratorInputOption>;
  // Interinput options
  iIO: Map<InputUUID, Array<ConfiguratorInputOption>>;
}

export const AdapterSelector = ConfiguratorInputOptionAdapter.getSelectors(
  (state: RootState) => state.configuratorInputOptions
);

export const getState = createSelector(
  (state: RootState) => state,
  (state) => state.configuratorInputOptions
);

/**
 * For a given product, it returns an Object including Maps of inputOptions from the product directly and from its operations,
 * and Maps of interInputOptions from the product directly and from its operation
 */
export const getConfiguratorInputOptionsProduct = memoize(
  (product?: Product) => {
    return createSelector(
      AdapterSelector.selectAll,
      OperationSelector.selectAll,
      (options, operations): ProductInputOptions => {
        const operationValid = operations.filter(
          (opts) =>
            product?.operationIds.includes(opts.id) &&
            (opts.internal || opts.external) &&
            opts.enabled
        );

        const iO = {
          fromOperations: new Map<InputUUID, ConfiguratorInputOption>(),
          fromProduct: new Map<InputUUID, ConfiguratorInputOption>()
        };

        const interIO = {
          fromOperations: new Map<InputUUID, Array<ConfiguratorInputOption>>(),
          fromProduct: new Map<InputUUID, Array<ConfiguratorInputOption>>()
        };

        const isFromOperation = (options: ConfiguratorInputOption) =>
          !!options.operationId &&
          operationValid.map((o) => o.id).includes(options.operationId);

        const isFromProduct = (options: ConfiguratorInputOption) =>
          product && !!options.productId && product.id === options.productId;

        for (const opt of options) {
          if (!opt.interInput) {
            if (
              isFromOperation(opt) &&
              !iO.fromOperations.has(opt.configuratorInputId)
            ) {
              //we arbitrary select one inputOption form one of the operations of the product
              iO.fromOperations.set(opt.configuratorInputId, opt);
            } else if (isFromProduct(opt)) {
              iO.fromProduct.set(opt.configuratorInputId, opt);
            }
          } else {
            if (isFromOperation(opt)) {
              const arr = interIO.fromOperations.get(opt.configuratorInputId);

              if (arr) arr.push(opt);
              else interIO.fromOperations.set(opt.configuratorInputId, [opt]);
            } else if (isFromProduct(opt)) {
              const arr = interIO.fromProduct.get(opt.configuratorInputId);

              if (arr) arr.push(opt);
              else interIO.fromProduct.set(opt.configuratorInputId, [opt]);
            }
          }
        }

        //select options from product first and fall back with ones from its operations
        const inputOptions = new Map<InputUUID, ConfiguratorInputOption>([
          ...Array.from(iO.fromOperations.entries()),
          ...Array.from(iO.fromProduct.entries())
        ]);

        const interInputOptions = new Map<
          string,
          Array<ConfiguratorInputOption>
        >([
          ...Array.from(interIO.fromOperations.entries()),
          ...Array.from(interIO.fromProduct.entries())
        ]);

        interInputOptions.forEach((interIO, inputId, list) => {
          if (!inputOptions.has(inputId)) list.delete(inputId);
          else interIO.sort((a, b) => b.priority - a.priority);
        });

        return { iO: inputOptions, iIO: interInputOptions };
      }
    );
  }
);

const inputIsVisibleByUser = (
  userRoles: EachUserRole,
  options?: ConfiguratorInputOption | Partial<ConfiguratorInputOptionComputed>
): boolean => {
  if (!options) return false;
  if (options.visible && options.visibleExcludedRoles) {
    return !options.visibleExcludedRoles.some((role) => userRoles[role]);
  }
  return options.visible ?? false;
};

/**
 * Return list of input option computed for all know inputs for the given product & position in the configurator
 */
export const getConfiguratorInputOptionsResult = memoize(
  (product?: Product, position?: string) => {
    return createSelector(
      ConfiguratorInputSelector.selectAll,
      getEachUserStandardRoleStatus,
      getConfiguratorInputOptionsProduct(product),
      getInputOptionsComputed(position),
      (inputs, userRoles, { iO }, iOComputed) => {
        return inputs.reduce((result, currInput) => {
          const inputOptionBase = iO.get(currInput.id);
          const inputOptionComputed = get(iOComputed, currInput.id);
          const precondition = inputOptionComputed?.precondition ?? false;
          const name = currInput.external
            ? (getExternalInputName(currInput.name) as ConfiguratorInputNames)
            : currInput.name;

          if (!product || !inputOptionBase) {
            result[currInput.name] = {
              linked: false,
              id: currInput.name,
              label: currInput.label,
              external: currInput.external,
              visible: false,
              visibleExcludedRoles: undefined,
              required: false,
              formula: "false",
              jsonLogicFormula: getJsonLogicFormulaAlways(false),
              interInputId: undefined,
              interInputFormula: undefined,
              precondition,
              name
            };
          } else {
            const inputOptionApplied = inputOptionComputed || inputOptionBase;

            //for debug purpose: keep base preconditions and add interInput formula
            const basePrecondition: Pick<
              ConfiguratorInputOptionComputed,
              | "formula"
              | "jsonLogicFormula"
              | "interInputId"
              | "interInputFormula"
            > = {
              formula: inputOptionBase.formula,
              jsonLogicFormula:
                inputOptionBase.jsonLogicFormula ||
                getJsonLogicFormulaAlways(false)
            };

            if (inputOptionApplied.id !== inputOptionBase.id) {
              basePrecondition.interInputId = inputOptionComputed?.id;
              basePrecondition.interInputFormula = inputOptionComputed?.formula;
            }

            result[currInput.name] = {
              linked: true,
              id: currInput.name,
              label: inputOptionApplied.label,
              helperText: inputOptionApplied.helperText,
              external: currInput.external,
              visible: inputIsVisibleByUser(userRoles, inputOptionApplied),
              visibleExcludedRoles: inputOptionApplied.visibleExcludedRoles,
              required: inputOptionApplied.required,
              options: inputOptionApplied.options,
              ...basePrecondition,
              precondition,
              name
            };
          }

          return result;
        }, {} as ConfiguratorInputOptionsResult);
      }
    );
  }
);

/**
 * Return the map of interInputOptions for the give product
 */
export const getInterInputOptionsResult = memoize((product?: Product) => {
  return createSelector(
    getConfiguratorInputOptionsProduct(product),
    getEachUserStandardRoleStatus,
    ConfiguratorInputSelector.selectAll,
    ({ iIO }, userRoles, inputs): BriefElementInterInputOptionsComputed => {
      const map = new Map<InputName, Array<InterInputOptionComputed>>();

      iIO.forEach((interInputOptions, inputId) => {
        const currInput = inputs.find((i) => i.id === inputId);
        if (!currInput) return;

        const optionsComputed = interInputOptions.map((opt) => ({
          ...opt,
          visible: inputIsVisibleByUser(userRoles, opt),
          jsonLogicFormula:
            opt?.jsonLogicFormula || getJsonLogicFormulaAlways(false)
        }));

        map.set(currInput.name, optionsComputed);
      });

      return map;
    }
  );
});

export const getWithRelations = createSelector(
  AdapterSelector.selectAll,
  ConfiguratorInputSelector.selectAll,
  OperationSelector.selectAll,
  ProductSelector.selectAll,
  (options, inputs, operations, products) =>
    options.map((option) => {
      const operation = operations.find((o) => option.operationId === o.id);
      const configuratorInput = inputs.find(
        (i) => option.configuratorInputId === i.id
      );
      const product = products.find((p) => option.productId === p.id);
      return {
        ...option,
        operation,
        configuratorInput,
        product
      };
    })
);

export const getOperationAndProductByInputId = (
  inputId: string,
  interInput = false
) =>
  createSelector(
    AdapterSelector.selectAll,
    getOperationsOptions,
    getProductOptions,
    (configuratorInputOptions, operations, products) => {
      if (!inputId) {
        return { productOptions: [], operationOptions: [] };
      }
      const inputOptions = configuratorInputOptions.filter(
        (opt) => opt.configuratorInputId === inputId
      );

      const productOptions = products.filter((product) =>
        interInput
          ? products
          : !inputOptions.map((opt) => opt.productId).includes(product.value)
      );
      const operationOptions = operations.filter((operation) =>
        interInput
          ? operation
          : !inputOptions
              .map((opt) => opt.operationId)
              .includes(operation.value)
      );
      return { productOptions, operationOptions };
    }
  );

// We try to get all inputs that's follow a product id or a operation id
// from configurator input options
// Used for create another input option
// Help for avoid no product or operation possible because
// all of them has been already taken
export const getInputsFilteredByConfiguratorInputOptions = (
  operationId?: string,
  productId?: string
) =>
  createSelector(
    AdapterSelector.selectAll,
    ConfiguratorInputSelector.selectAll,
    OperationSelector.selectTotal,
    getTotalProduct,
    (inputOptions, inputs, operationCount, productCount) =>
      inputs
        .filter((input) => {
          const options = inputOptions.filter(
            (io) => io.configuratorInputId === input.id
          );

          if (
            (operationId &&
              options.some((o) => o.operationId === operationId)) ||
            (productId && options.some((o) => o.productId === productId))
          ) {
            return false;
          }
          const operationOptions = options.filter((o) => !!o.operationId);

          const productOptions = options.filter((o) => !!o.productId);
          return !(
            operationOptions.length === operationCount &&
            productOptions.length === productCount
          );
        })
        .map((opt) => ({
          value: opt.id,
          label: opt.name
        }))
  );

const getConfiguratorInputOptionsById = (id: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => AdapterSelector.selectById(state, id)
  );

export const ConfiguratorInputOptionSelector = {
  ...AdapterSelector,
  getWithRelations,
  getState,
  getConfiguratorInputOptionsResult,
  getOperationAndProductByInputId,
  getInputsFilteredByConfiguratorInputOptions,
  getConfiguratorInputOptionsById
};
