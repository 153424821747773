import { FormikErrors, FormikTouched } from "formik";
import { every, pickBy, some } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "..";
import { MyExpansionPanelStatus } from "../../components/common/MyExpansionPanel";
import { ProductCategoryName } from "../../entities/productCategory";
import {
  ConfiguratorInputOptionsComputed,
  getConfiguratorInputOptionsResult
} from "../configurator-input-options/selector";
import {
  ConfiguratorInputMandatory,
  ConfiguratorInputNames,
  SectionNames
} from "../configurator-inputs/constant";
import { Product } from "../products/entity";
import {
  BriefElementConfiguratorState,
  BriefElementConfiguratorValues
} from "./reducer";

export const getBriefElementsConfiguratorState = createSelector(
  (state: RootState) => state,
  (state) => state.briefElementConfigurator
);

export const getBriefElementsCount = createSelector(
  (state: RootState) => state,
  (state) => Object.keys(state.briefElementConfigurator).length
);

export const getBriefElementStateByPosition = (position: string) =>
  createSelector(
    (state: RootState) => state.briefElementConfigurator,
    (configurator: BriefElementConfiguratorState) => configurator[position]
  );

export const getBriefElementSectionState = (position: string) =>
  createSelector(
    getBriefElementStateByPosition(position),
    (configurator) => configurator?.sectionsState
  );

export const getBriefElementSectionStateByName = (
  position: string,
  name: SectionNames
) =>
  createSelector(getBriefElementSectionState(position), (sectionState) =>
    sectionState ? sectionState[name] : undefined
  );

export const getBriefElementSectionPropsByName = (
  position: string,
  name: SectionNames,
  values: BriefElementConfiguratorValues,
  productSelected?: Product
) =>
  createSelector(
    getBriefElementStateByPosition(position),
    getBriefElementSectionStateByName(position, name),
    getConfiguratorInputOptionsResult(productSelected, position),
    (briefElementState, panelState, configuratorInputOptionResult) => {
      const foldingBoxWithoutMaterialReference =
        !briefElementState?.productCategorySelected ||
        !briefElementState?.productSelected ||
        (briefElementState.productCategorySelected.name ===
          ProductCategoryName.FoldingBoxes &&
          !values.materialReferenceId);

      const sectionsProps = {
        [SectionNames.PACKAGING_TYPE]: { disabled: false },
        [SectionNames.MATERIALS]: {
          disabled:
            !briefElementState?.productCategorySelected ||
            !briefElementState?.productSelected
        },
        [SectionNames.DECORATIONS]: {
          disabled: foldingBoxWithoutMaterialReference
        },
        [SectionNames.FINISHES]: {
          disabled: foldingBoxWithoutMaterialReference
        },
        [SectionNames.PURCHASE_CONDITION]: {
          disabled: foldingBoxWithoutMaterialReference
        }
      }[name];

      return {
        expanded: panelState?.isExpanded,
        hidden:
          productSelected && panelState
            ? panelState.dependentProperties.filter(
                (depProp) =>
                  // only based on preconditions
                  (configuratorInputOptionResult[depProp]?.visible &&
                    !configuratorInputOptionResult[depProp].interInputId) ||
                  ConfiguratorInputMandatory.includes(depProp)
              ).length === 0
            : false,
        ...sectionsProps
      };
    }
  );

export const getBriefElementExpansionPanelStatusFromSection = (
  position: string,
  sectionName: SectionNames,
  touched: FormikTouched<BriefElementConfiguratorValues>,
  errors: FormikErrors<BriefElementConfiguratorValues>
) =>
  createSelector(getBriefElementSectionState(position), (sectionState) => {
    const currentSection = sectionState ? sectionState[sectionName] : undefined;
    const expansionPanelErrors = pickBy(errors, (_, key) =>
      currentSection?.dependentProperties.includes(
        key as ConfiguratorInputNames
      )
    );

    if (!currentSection?.touched) {
      return { status: undefined, errors: expansionPanelErrors };
    }
    if (
      some(
        currentSection.dependentProperties,
        (ptp) =>
          (touched as Record<ConfiguratorInputNames, any>)[ptp] &&
          (errors as Record<ConfiguratorInputNames, any>)[ptp]
      )
    ) {
      return {
        status: MyExpansionPanelStatus.ERROR,
        errors: expansionPanelErrors
      };
    }
    if (
      every(
        errors,
        (_, key) =>
          !currentSection.dependentProperties.includes(
            key as ConfiguratorInputNames
          )
      )
    ) {
      return { status: MyExpansionPanelStatus.SUCCESS };
    }
    return { status: undefined, errors: expansionPanelErrors };
  });

export const getBriefElementProductSelected = (position: number) =>
  createSelector(
    (state: RootState) =>
      Object.entries(state.briefElementConfigurator)[position][1],
    (briefElement) => briefElement?.productSelected
  );

export const getBriefElementProductSelectedScale = (position: number) =>
  createSelector(
    (state: RootState) =>
      Object.entries(state.briefElementConfigurator)[position][1],
    (briefElement) => briefElement?.scale3D
  );

export const getProductVisibilityState = (position: number) =>
  createSelector(
    (state: RootState) =>
      Object.entries(state.briefElementConfigurator)[position][1],
    (briefElement) => briefElement?.productVisibilityState
  );

export const getIsProductFromUrl = () =>
  createSelector(
    (state: RootState) => state.briefElementConfigurator,
    (configurator: BriefElementConfiguratorState) =>
      configurator[0]?.productFromUrl
  );

export const getBriefElementAllValid = createSelector(
  getBriefElementsConfiguratorState,
  (briefElementState) => every(briefElementState, (be) => be?.isValid === true)
);

/**
 * Returns the input options computed for the brief element.
 * These input options can be from activated inter input options.
 * It specify where or not the base precondition is met for the linked input ids.
 * If input options has formulas, it has been computed into its corresponding property.
 * e.g. options.defaultFormula computed as options.default
 * @param position of the brief element
 * @returns input options for the brief element
 */
export const getInputOptionsComputed = (position?: string) => {
  if (!position) {
    return createSelector(
      (state: RootState) => state,
      () => ({} as ConfiguratorInputOptionsComputed)
    );
  }
  return createSelector(
    getBriefElementStateByPosition(position),
    (sectionState) =>
      sectionState?.inputOptionsComputed ||
      ({} as ConfiguratorInputOptionsComputed)
  );
};

/**
 * Used to store the values of the brief element in specific cases:
 * - when the user switches to/create another brief element
 * - during initialisation when product is automatically selected from url
 * - during duplication of a brief
 * Warning: don't use it as source of truth for the brief element
 * during the configuration phase. Use formik state instead.
 * @param position of the brief element
 * @returns values of the brief element
 */
export const getBriefElementStateValues = (position: string) =>
  createSelector(
    getBriefElementStateByPosition(position),
    (sectionState) => sectionState?.values || {}
  );
