import { jsonTranslator } from "./../../utils/function/jsonTranslator";
import { AxiosError } from "axios";
import { createSelector } from "reselect";
import { AppAddSnackbar } from "../../reducers/app/action";
import { getLanguage } from "../../reducers/app/selector";
import { RootStateQuery, store } from "../../reducers/store";
import { apiSlice } from "../../utils/api/api";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { EntityType, EntityTypeCurrent } from "../events/type";
import {
  addOptionsToStatus,
  StatusOptions,
  transformStatus
} from "./transform";
import { Status, StatusWOptions } from "./type";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Statuses", "EntityStatuses", "EntityOptionStatuses"]
});

interface StatusByType {
  entity: EntityType | EntityTypeCurrent;
  options?: boolean;
}

const extendedApiSlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getStatuses: build.query<Status[], void>({
      query: () => "/statuses",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Statuses" as const,
                id
              })),
              { type: "Statuses", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Statuses", id: "PARTIAL-LIST" }]
    }),
    getStatusesByEntity: build.query<StatusWOptions[], StatusByType>({
      query: ({ entity }) => {
        return {
          url: `/statuses/entity?entity=${entity}`,
          method: "GET"
        };
      },
      transformResponse: (responseData: StatusWOptions[], __, { options }) => {
        if (!options) {
          return responseData;
        } else {
          const language = store.getState().app.language;
          const currentUser = store.getState().authentication.user;
          return addOptionsToStatus(responseData, language, currentUser);
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ entity }) => ({
                type: "EntityStatuses" as const,
                entity
              })),
              { type: "EntityStatuses", id: "PARTIAL-LIST" }
            ]
          : [{ type: "EntityStatuses", id: "PARTIAL-LIST" }]
    }),
    getStatusesOptionsByEntity: build.query<StatusOptions[], StatusByType>({
      query: ({ entity }) => `/statuses/entity?entity=${entity}`,
      transformResponse: (responseData: Status[]) => {
        const language = store.getState().app.language;
        const currentUser = store.getState().authentication.user;
        return transformStatus(responseData, language, currentUser);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ value }) => ({
                type: "EntityOptionStatuses" as const,
                value
              })),
              { type: "EntityOptionStatuses", id: "PARTIAL-LIST" }
            ]
          : [{ type: "EntityOptionStatuses", id: "PARTIAL-LIST" }]
    }),
    createStatuses: build.mutation<Status, Partial<Status>>({
      query(dto) {
        return {
          url: "/statuses",
          method: "POST",
          body: dto
        };
      },
      invalidatesTags: () => [
        { type: "EntityStatuses", id: "PARTIAL-LIST" },
        { type: "Statuses", id: "PARTIAL-LIST" },
        { type: "EntityOptionStatuses", id: "PARTIAL-LIST" }
      ],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    updateStatuses: build.mutation<Status, Partial<Status>>({
      query(dto) {
        const { id, ...patch } = dto;
        return {
          url: `/statuses/${id}`,
          method: "PATCH",
          body: patch
        };
      },
      invalidatesTags: () => [
        { type: "EntityStatuses", id: "PARTIAL-LIST" },
        { type: "Statuses", id: "PARTIAL-LIST" },
        { type: "EntityOptionStatuses", id: "PARTIAL-LIST" }
      ],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    deleteStatuses: build.mutation<Status, Partial<Status>>({
      query({ id }) {
        return {
          url: `/statuses/${id}`,
          method: "DELETE"
        };
      },
      invalidatesTags: () => [
        { type: "EntityStatuses", id: "PARTIAL-LIST" },
        { type: "Statuses", id: "PARTIAL-LIST" },
        { type: "EntityOptionStatuses", id: "PARTIAL-LIST" }
      ],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:delete-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:delete-failed")
            )
          );
        }
      }
    })
  }),
  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetStatusesQuery,
  useGetStatusesByEntityQuery,
  useGetStatusesOptionsByEntityQuery,
  useLazyGetStatusesOptionsByEntityQuery,
  useCreateStatusesMutation,
  useDeleteStatusesMutation,
  useUpdateStatusesMutation,
  util: statusServiceUtil
} = extendedApiSlice;

// Until we have status set by default in the DB
export const getNewProjectStatus = createSelector(
  getLanguage,
  (state: RootStateQuery) =>
    state.api.queries['getStatusesOptionsByEntity({"entity":"PROJECTS"})']
      ?.data as StatusOptions[],
  (lang, statuses) =>
    // TODO change it when implementing project statuses
    statuses?.find(
      (item) =>
        item.translation![lang] === "New" ||
        item.translation![lang] === "Nouveau"
    ) || undefined
);

export const getStatusesOptionsByEntity = (entity: EntityType) =>
  createSelector(
    getLanguage,
    (state: RootStateQuery) => {
      return state.api?.queries[
        `getStatusesOptionsByEntity({"entity":"${entity}"})`
      ]?.data as StatusOptions[];
    },
    (lang, statuses) =>
      statuses?.map((item) => {
        return { ...item, label: jsonTranslator(item.translation!, lang) };
      }) || undefined
  );
